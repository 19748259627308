<template>
  <main>
    <headerMain/>

    <vacancy__body/>

    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";
import vacancy__body from "@/components/vacancy__body";


export default {
  name: 'contact',
  components: {
    footerMain, headerMain, vacancy__body

  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Вакансии'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
