import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollReveal from 'vue-scroll-reveal';
import CursorFx from '@luxdamore/vue-cursor-fx';
import '@luxdamore/vue-cursor-fx/dist/CursorFx.css';

import Vuelidate from 'vuelidate'
import Meta from 'vue-meta'
import VueI18n from 'vue-i18n'
import {languages} from "@/i18n";
import {defaultLocale} from "@/i18n";

import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
    // options here
})
Vue.use(VueI18n,);
const messages = Object.assign(languages)
// VueI18n instance
const i18n = new VueI18n({
    locale: defaultLocale, messages
})

Vue.config.productionTip = false

Vue.use(CursorFx,);


Vue.use(Vuelidate)
Vue.use(VueScrollReveal, {
    class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
    duration: 800,
    scale: 0.5,
    distance: '50px',
    mobile: true,
    reset: false
});
Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})


import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
Vue.use(PrimeVue);
import Dialog from 'primevue/dialog';

Vue.component('Dialog', Dialog);
Vue.component('Dropdown', Dropdown);

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')

