<template>
  <section class="support">
    <vs-dialog v-model="active" not-center width="550px">
      <template #header>
        <h4 style="margin-top: 20px">
          {{ mes }}
        </h4>
      </template>


      <div class="con-content">
        <p>
          {{ $t('utils.remember') }}
        </p>
      </div>
      <template #footer>
        <div class="con-footer" style="display: flex;justify-content: center">
          <vs-button class="btn body-c" transparent @click="active2=false, active=false">
            {{ $t('utils.close') }}
          </vs-button>
        </div>
      </template>
    </vs-dialog>
    <div class="container-fluid">
      <div class="support__block">
        <div class="support__form">
          <div v-scroll-reveal="{ delay: 200 }" class="form">
            <form @submit="form" action="#!">
              <input v-model="name" :placeholder="$t('contactBlock.input.name')" required type="text">
              <input v-model="phone" v-maska="'+998(##)-###-##-##'" :placeholder="$t('contactBlock.input.phone')" required
                     type="text">
              <textarea v-model="message" :placeholder="$t('contactBlock.input.message')" required>

        </textarea>
              <button class="btn" data-cursor-hover>
                {{ $t('contactBlock.input.message') }}
              </button>
            </form>
          </div>
        </div>
        <div class="support__text">
          <div class="support__text-block">
            <h2 v-scroll-reveal="{ delay: 250 }">
              {{$t('contactBlock.application')}}
            </h2>
            <p v-scroll-reveal="{ delay: 300 }">
              {{$t('contactBlock.weContact')}}
            </p>
          </div>
          <div class="support__text-img">
            <img v-scroll-reveal="{ delay: 350 }" :src=illustUrl alt="">
          </div>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import {maska} from 'maska'
import axios from "axios";

export default {
  name: "support",

  data: function () {
    return {
      illustUrl: 'img/support.png',
      name: '',
      phone: '',
      message: '',
      active: false,
      input1: '',
      input2: '',
      mes: ''

    }
  },
  directives: {maska},
  methods: {
    form(e) {
     axios.post('https://admin.optikom.uz/api/v1/web/postTicket', {
        name: this.name,
        phone: this.phone,
        message: this.message,
        tariff_id: null,
      }, {
        headers: {
          lang: 'ru'
        }
      })
          .then(response => (this.mes = response.data.message, this.active = true))
          .catch(function (error) {
            console.log(error);
          });

      e.preventDefault();


    },

  }

}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.support {
  padding: 100px 0;

  &__block {
    display: flex;
    align-items: center;
  }

  &__form {
    display: flex;
    width: 50%;


    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: $colorWhite;
      padding: 50px;
      border-radius: 50px;
      max-width: 600px;

      form {
        width: 100%;

      }

      input {
        padding: 25px 30px;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid #DDDDDE;
        border-radius: 25px;
      }

      ::placeholder {
        color: $colorBlack;
      }

      textarea {
        width: 100%;
        padding: 25px 30px;
        border: 1px solid #DDDDDE;
        border-radius: 25px;
        margin-bottom: 30px;
      }

      button {
        width: 100%;
        padding: 25px;
        text-align: center;
        justify-content: center;
      }
    }


  }

  &__text {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 50px;

    &-block {
      max-width: 400px;

      h2 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 100%;
        letter-spacing: -0.025em;
        color: $colorBlack;
      }

      p {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 130%;
        letter-spacing: -0.025em;
        color: $colorBlack;
      }
    }

    &-img {
      margin-top: 25px;

      img {
        width: 100%;
      }
    }

  }

  @media screen and (max-width: 1000px) {
    .support__block {
      flex-direction: column;
      justify-content: center;


      .support__form {
        order: 5;
        width: 100% !important;
        display: flex;
        justify-content: center;
      }

      .support__text {
        margin-bottom: 60px;
        justify-content: center;
        padding: 0 !important;
        width: 100%;
        display: flex;
        flex-direction: row !important;
        align-items: center;

        &-block {
          width: 50% !important;
        }

        &-img {
          width: 50% !important;
        }
      }
    }

  }
  @media screen and (max-width: 800px) {
    .support__block {

      .support__form {

        .form {
          padding: 30px;

          input {
            padding: 20px 30px;
            margin-bottom: 20px;
          }

          textarea {
            padding: 20px 30px;
          }
        }
      }

      .support__text {
        margin-bottom: 20px;

        &-block {
          width: 100% !important;

        }

        &-img {
          display: none;
          order: -1;
          width: 80% !important;
          margin-bottom: 60px;
        }
      }
    }

  }
  @media screen and (max-width: 400px) {
    .support__block {

      .support__form {

        .form {
          padding: 20px;
          border-radius: 20px;

          input {
            padding: 15px;
            border-radius: 15px;
            margin-bottom: 20px;
          }

          textarea {
            padding: 15px;
            border-radius: 15px;
            margin-bottom: 20px;
          }
        }
      }

      .support__text {
        flex-direction: column !important;

        &-block {
          width: 100% !important;

        }

        &-img {
          order: -1;
          width: 80% !important;
          margin-bottom: 60px;
        }
      }
    }

  }
}


</style>