<template>
  <section class="more">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          Что еще доступно?
        </h2>
      </div>

      <div class="more__block">
        <div v-scroll-reveal="{ delay: 350 }" class="more__item">
          <div class="more__item-title">
            <h3>
              {{ $t('service.services.fourth') }}
            </h3>
          </div>
          <div class="more__item-poster">
            <img :src=servicePosters[3].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 450 }" class="more__item">
          <div class="more__item-title">
            <h3>
              {{ $t('service.services.sixth') }}
            </h3>
          </div>
          <div class="more__item-poster">
            <img :src=servicePosters[5].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 550 }" class="more__item">
          <div class="more__item-title">
            <h3>
              {{ $t('service.services.second') }}
            </h3>
          </div>
          <div class="more__item-poster">
            <img :src=servicePosters[1].imgUrl alt="Интернет">
          </div>
        </div>
      </div>


      <div class="more__btn">
        <router-link class="btn body-c big" to="">
          {{ $t('service.more') }}
        </router-link>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "internet-whats-more", data: function () {
    return {
      servicePosters: [
        {
          name: this.$t('service.services.first'),
          imgUrl: 'img/services/service-1.png'
        },
        {
          name: this.$t('service.services.second'),
          imgUrl: 'img/services/service-2.png'
        },
        {
          name: this.$t('service.services.third'),
          imgUrl: 'img/services/service-3.png'
        },
        {
          name: this.$t('service.services.fourth'),
          imgUrl: 'img/services/service-4.png'
        },
        {
          name: this.$t('service.services.fifth'),
          imgUrl: 'img/services/service-5.png'
        },
        {
          name: this.$t('service.services.sixth'),
          imgUrl: 'img/services/service-6.png'
        },
        {
          name: this.$t('service.services.seventh'),
          imgUrl: 'img/services/service-7.png'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";


.more {
  background: $colorWhite;
  padding: 100px 0;

  &__btn {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }


  .section__title {
    margin-bottom: 40px;
  }

  .more__block {

    display: flex;
    flex-wrap: wrap;
    gap: 30px 2%;


    .more__item {
      display: flex;
      width: 32%;
      position: relative;
      padding: 30px 0 30px 30px;
      background: $bodyColor;
      border-radius: 35px;
      align-items: center;
      //height: 250px;
      overflow: hidden;
      @extend .transition;

      &:hover {
        transform: translateY(-20px) scale(1);
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0) scale(0.95);
        box-shadow: none;

      }

      &-title {
        width: 50%;

        h3 {
          font-weight: 500;
          font-size: 1.4rem;
        }
      }

      &-poster {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
          width: 80%;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .more__item {
      border-radius: 30px;
    }
  }

  @media screen and (max-width: 1000px) {
    .section__title.center.d-none {
      display: flex !important;
    }
    .more__title {
      display: none;
    }
    .more__block {
      flex-wrap: wrap;
    }
    .more__item {
      width: 49% !important;

      &:last-child {
        width: 100% !important;

        .more__item-poster {
          //height: 250px !important;

          img {
            max-width: 180px !important;
          }
        }

      }
    }
  }
  @media screen and (max-width: 780px) {
    .more__item {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .more__item {
      border-radius: 25px !important;

      &-title {
        h3 {
          font-size: 1.2rem;

        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .more__item {
      border-radius: 20px !important;

      &-title {
        h3 {
          font-size: 1.1rem !important;

        }
      }
    }
  }


}

</style>