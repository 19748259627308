<template>
  <main>
    <headerMain/>
    <bigSlider/>
    <tvCanals/>


    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import bigSlider from "@/components/bigSlider";
import footerMain from "@/components/rp-blocks/footer";
import tvCanals from "@/components/tv-canals";


export default {
  name: 'tv',
  components: {
    headerMain, bigSlider, footerMain, tvCanals

  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Телевидение'
  }

}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
