<template>
  <Dropdown v-model="selectedCountry" :options="countries" optionLabel="name" placeholder="Select a Country" @change="setLocaleSelect()">
    <template #value="slotProps">
      <div v-if="slotProps.value" class="p-dropdown-car-value">
        <img :alt="slotProps.value.code" :src="'img/flags/' + slotProps.value.code + '.png'"/>
        <span>{{ slotProps.value.name }}</span>
      </div>
      <span v-else>
            {{ slotProps.placeholder }}
        </span>
    </template>
    <template #option="slotProps">
      <div class="p-dropdown-car-option">
        <img :alt="slotProps.option.code" :src="'img/flags/' + slotProps.option.code + '.png'"/>
        <span>{{ slotProps.option.name }}</span>
      </div>
    </template>
  </Dropdown>

</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: "changeLanguage",
  components: {
    Dropdown
  },
  data: function () {

    return {
      activeLang: "ru",
      selectedCountry: '',
      countries: [
        {name: this.$t('utils.uz'), code: 'uz'},
        {name: this.$t('utils.ru'), code: 'ru'},
      ]
    };
  },
  methods: {
    setLocaleSelect() {
      localStorage.setItem('lang', this.selectedCountry.code)
      this.$i18n.locale = this.selectedCountry.code;

    },
  },
  created() {
    const lang = localStorage.getItem('lang')
    this.$i18n.locale = lang
    if(lang==='uz') {
      this.selectedCountry = this.countries[0]
    } else {
      this.selectedCountry = this.countries[1]
    }

  },
  mounted() {

  }
}
</script>

<style lang="scss">
.p-dropdown {
  margin-right: 1rem;
  * {
    outline: none;
  }

  border-radius: 0.7rem;
  padding-right: 8px;
  border: 1px solid #E1E2E3;
}

.p-dropdown-items {
  display: flex;
  flex-direction: column;

  img {
    width: 20px;
    margin-right: 5px;
  }
}

.p-dropdown-car-value, .p-dropdown-car-option {
  img {
    width: 20px;
    margin-right: 5px;
  }

  span {
    font-size: 0.8rem;
  }
}

.p-dropdown-panel {
  border-radius: 0.5rem;
}

.p-dropdown-items-wrapper {
  width: 100%;
}
.p-dropdown .p-dropdown-trigger {
  width: auto;
  height: auto;
  padding: 0;
}
.p-dropdown-trigger {



}

</style>