<template>
  <main>
    <headerMain/>
    <bigSlider data-cursor-hover/>
    <service-items/>
    <footerMain/>
  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";
import bigSlider from "@/components/bigSlider";
import serviceItems from "@/components/serviceItems";


export default {
  name: 'about',
  components: {
    bigSlider, headerMain, footerMain, serviceItems

  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Наши сервисы'
  }

}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
