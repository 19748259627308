<template>
  <main>
    <headerMain/>
    <bigSlider data-cursor-hover/>
    <aboutInfo/>
    <aboutTeam/>
    <footerMain/>
  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";
import aboutInfo from "@/components/about-info";
import aboutTeam from "@/components/about-team";
import bigSlider from "@/components/bigSlider";


export default {
  name: 'about',
  components: {
    bigSlider,headerMain,footerMain, aboutInfo, aboutTeam

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OPTIKOM',
    // all titles will be injected into this template
    titleTemplate: '%s | О нас'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
