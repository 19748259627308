<template>
  <section class="services">
    <div class="container-fluid">
      <div class="section__title center d-none">
        <h2>
          {{$t('servicesBlock.title')}}
        </h2>
      </div>

      <div class="service">
        <div v-scroll-reveal="{ delay: 150 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.internet')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[0].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 250 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.smartHome')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[1].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 350 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.security')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[2].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 450 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.IPTV')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[3].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 550 }" class=" service__title section__title">
          <h2>
            {{$t('servicesBlock.title')}}
          </h2>

        </div>
        <div v-scroll-reveal="{ delay: 650 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.IPPhone')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[4].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 750 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.mediaPortal')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[5].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 850 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.coLocation')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[6].imgUrl alt="Интернет">
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 950 }" class="service__item">

          <div class="service__item-name">
            <h3>
              {{$t('servicesBlock.services.installationWork')}}
            </h3>

          </div>
          <div class="service__item-poster">
            <img :src=servicePosters[7].imgUrl alt="Интернет">
          </div>
        </div>


      </div>
    </div>


  </section>
</template>

<script>
export default {
  name: "services",
  data: function () {
    return {
      servicePosters: [
        {
          name: 'Интернет',
          imgUrl: 'img/services/service-1.png'
        },
        {
          name: 'Умный дом',
          imgUrl: 'img/services/service-2.png'
        },
        {
          name: 'Безопасность',
          imgUrl: 'img/services/service-3.png'
        },
        {
          name: 'IP Телевидение',
          imgUrl: 'img/services/service-4.png'
        },
        {
          name: 'IP Телефония',
          imgUrl: 'img/services/service-5.png'
        },
        {
          name: 'Медиа портал',
          imgUrl: 'img/services/service-6.png'
        },
        {
          name: 'Co-location',
          imgUrl: 'img/services/service-7.png'
        },
        {
          name: ' Монтажные работы',
          imgUrl: 'img/services/service-8.png'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.services {
  padding: 100px 0;
  background: $colorGray;

  .section__title.center {
    margin-bottom: 40px;
    &.d-none {
      display: none;
    }
  }

  .service {

    display: flex;
    flex-wrap: wrap;
    gap: 30px 2%;


    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32%;


    }

    &__item {
      display: flex;
      width: 32%;
      position: relative;
      padding: 30px 0 30px 30px;
      background: $colorWhite;
      border-radius: 50px;
      align-items: center;
      //height: 250px;
      overflow: hidden;
      @extend .transition;

      &:hover {
        transform: translateY(-20px) scale(1);
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0) scale(0.95);
        box-shadow: none;

      }

      &-name {
        width: 50%;

        h3 {
          font-weight: 500;
          font-size: 1.4rem;
        }
      }

      &-poster {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
          width: 80%;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .service__item {
      border-radius: 30px;
    }
  }

  @media screen and (max-width: 1000px) {
    .section__title.center.d-none {
      display: flex !important;
    }
    .service__title {
      display: none;
    }
    .service__item {
      width: 49%;

    }
  }
  @media screen and (max-width: 700px) {
    .service__item {
      width: 100%;

    }
  }
  @media screen and (max-width: 450px) {
    .service__item {
      &-name {
        h3 {
          font-size: 1.2rem;

        }
      }
    }
  }
}


</style>