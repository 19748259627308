<template>
  <section class="connect">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('internet.connect.title') }}
        </h2>
      </div>

      <div class="connect__block">
        <div v-scroll-reveal="{ delay: 350 }" class="connect__item">
          <div class="connect__item-step">
            <span>
              1
            </span>
          </div>
          <div class="connect__item-title">
            <h3>
              {{ $t('internet.connect.steps.first.title') }}
            </h3>
            <p>
              {{ $t('internet.connect.steps.first.body') }}
            </p>
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 450 }" class="connect__item">
          <div class="connect__item-step">
            <span>
              2
            </span>
          </div>
          <div class="connect__item-title">
            <h3>
              {{ $t('internet.connect.steps.second.title') }}
            </h3>
            <p>
              {{ $t('internet.connect.steps.second.body') }}
            </p>
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 550 }" class="connect__item">
          <div class="connect__item-step">
            <span>
              3
            </span>
          </div>
          <div class="connect__item-title">
            <h3>
              {{ $t('internet.connect.steps.third.title') }}
            </h3>
            <p>
              {{ $t('internet.connect.steps.third.body') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "internet-connect"
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.connect {
  background: $bodyColor;
  padding: 100px 0;

  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 30px 2%;

    .connect__item {
      background: $colorWhite;
      width: 32%;

      padding: 20px 25px;
      border-radius: 20px;

      &-step {
        margin-bottom: 10px;

        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background: $colorLightBlue;
          color: $colorBlue;
          font-weight: 700;
          font-size: 25px;
          line-height: 140%;
          border-radius: 15px;

        }

      }

      &-title {
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 120%;
          margin-bottom: 5px;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 140%;
          color: $colorBlack;
        }
      }

    }
  }

  @media screen and (max-width: 1000px) {
    .connect__item {
      width: 49%;
    }
  }

  @media screen and (max-width: 600px) {
    .connect__item {
      width: 100%;
    }
  }
}

</style>