<template>
  <section>
    <div style="position:relative;overflow:hidden;"><a
        href="https://yandex.com/maps/org/optikom/63635065331/?utm_medium=mapframe&utm_source=maps"
        style="color:#eee;font-size:12px;position:absolute;top:0px;">Optikom</a><a
        href="https://yandex.com/maps/10335/tashkent/category/internet_service_provider/184105748/?utm_medium=mapframe&utm_source=maps"
        style="color:#eee;font-size:12px;position:absolute;top:14px;">Интернет-провайдер в Ташкенте</a><a
        href="https://yandex.com/maps/10335/tashkent/category/telecommunication_company/184107799/?utm_medium=mapframe&utm_source=maps"
        style="color:#eee;font-size:12px;position:absolute;top:28px;">Телекоммуникационная компания в Ташкенте</a>
      <iframe allowfullscreen="true" frameborder="1" height="400" src="https://yandex.com/map-widget/v1/-/CCUFuOEB3B"
              style="position:relative;" width="560"></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "contact-map"
}
</script>

<style lang="scss" scoped>
section {
  height: 700px;
}
iframe {
  width: 100%;
  height: 700px;
}

</style>