<template>
  <main>
    <headerMain/>
    <homeBanner/>

    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import footerMain from "@/components/rp-blocks/footer";
import homeBanner from "@/components/homeBanner";


export default {
  name: 'corporate',
  components: {
    headerMain, footerMain, homeBanner

  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Для дома'
  },


}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
