<template>
  <section class="about">
    <div class="container-fluid">
      <div class="about__block">
        <div v-scroll-reveal="{ delay: 350 }" class="about__item">
          <div class="about__item-logo">
            <img src="../assets/optikom.svg" alt="Optikom" width="500">
          </div>
        </div>

        <div v-scroll-reveal="{ delay: 400 }" class="about__item">
          <p>
            {{ $t('aboutUs.aboutOptikom') }}
          </p>
        </div>
      </div>

      <div class="about__block best">
        <div v-scroll-reveal="{ delay: 450 }" class="about__item">
          <div class="about__item-icon">
            <span>
              <i class="icon-signal"></i>
            </span>
          </div>
          <div class="about__item-title">
            <h3>
              {{ $t('aboutUs.features.first') }}
            </h3>
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 500 }" class="about__item">
          <div class="about__item-icon">
            <span>
              <i class="icon-signal"></i>
            </span>
          </div>
          <div class="about__item-title">
            <h3>
              {{ $t('aboutUs.features.second') }}
            </h3>
          </div>
        </div>
        <div v-scroll-reveal="{ delay: 550 }" class="about__item">
          <div class="about__item-icon">
            <span>
              <i class="icon-signal"></i>
            </span>
          </div>
          <div class="about__item-title">
            <h3>
              {{ $t('aboutUs.features.third') }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "about-info",
  data: function () {
    return {
      logoUrl: 'img/optikom.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.about {
  padding: 100px 0;

  &__block {
    display: flex;
    gap: 30px 2%;
    flex-wrap: wrap;
    align-items: center;

    .about__item {
      width: 49% !important;
      display: flex;

      p {
        margin: 0;
      }

      &-logo {
        width: 100%;

        img {
          width: 100%;
          max-width: 500px;
        }
      }
    }

    &.best {
      align-items: stretch;
      flex-wrap: wrap;
      margin-top: 40px;

      .about__item {
        width: 32% !important;
        border-radius: 20px;
        background: $colorWhite;
        padding: 30px;
        align-items: center;
        justify-content: flex-start !important;

        &-icon {
          margin-right: 15px;

          span {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $colorLightBlue;
            border-radius: 25px;


            i {
              font-size: 3rem;
              color: $colorBlue;
              margin-bottom: 0;
            }
          }
        }

        &-title {
          h3 {
            max-width: 310px;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 132%;
            letter-spacing: -0.025em;
            margin-bottom: 0;
          }
        }
      }
    }


  }


  @media screen and (max-width: 1300px) {
    .best {
      .about__item {
        padding: 15px;

        &-icon {
          margin-right: 15px;

          span {
            width: 80px !important;
            height: 80px !important;
            border-radius: 20px !important;

            i {
              font-size: 2.5rem !important;
            }
          }
        }

        &-title {
          h3 {
            font-size: 1.1rem !important;

          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .about__item {
      width: 100% !important;

      &-logo {
        display: flex;
        justify-content: center;
      }
    }
    .best {
      .about__item {
        padding: 15px;
        width: 49% !important;

      }
    }
  }

  @media screen and (max-width: 700px) {
    .about__item {
      width: 100% !important;
    }
    .best {
      .about__item {
        padding: 15px;
        width: 100% !important;
        //justify-content: center !important;

      }
    }
  }

  @media screen and (max-width: 380px) {
    .about__item {
    }
    .best {
      .about__item {

        &-icon {
          margin-right: 15px;

          span {
            width: 70px !important;
            height: 70px !important;
            border-radius: 25px !important;
            i {
              font-size: 2rem !important;
            }
          }
        }

        &-title {
          h3 {
            font-size: 0.9rem !important;

          }
        }


      }
    }
  }

}

</style>