<template>
  <main>
    <headerMain/>
    <bigSlider/>
    <internetWhy/>
    <tariffs/>
    <internetWhatsmore/>
    <internetConnect/>
    <support/>


    <footerMain/>

    <div class="d-none">


    </div>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import bigSlider from "@/components/bigSlider";
import footerMain from "@/components/rp-blocks/footer";
import internetWhy from "@/components/internet-why";
import tariffs from "@/components/newTarifBlock.vue";
import internetWhatsmore from "@/components/internet-whatsmore";
import internetConnect from "@/components/internet-connect";
import support from "@/components/support";


export default {
  name: 'internet',
  components: {
    headerMain, bigSlider, footerMain, internetWhy, tariffs, internetWhatsmore, internetConnect, support

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OPTIKOM',
    // all titles will be injected into this template
    titleTemplate: '%s | Интернет'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
