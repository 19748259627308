<template>
  <main>
    <headerMain/>
    <vacancyDetailBody
        :title="vacancy.name"
        :vacancy_text="vacancy.text"
        :vacancy_id="vacancy.id"
    />

    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";
import vacancyDetailBody from "@/components/vacancyDetail-body";
import axios from "axios";


export default {
  name: 'contact',
  data: function () {
    return {
      vacancy: []

    }
  },
  components: {
    footerMain, headerMain, vacancyDetailBody

  },
  async created() {
    const vacancy = await axios.get(`https://admin.optikom.uz/api/v1/web/getVacancyDetail`, {
      headers: {
        'lang': 'ru',
        'vacancy-id': this.$route.params.id
      }
    });
    this.vacancy = vacancy.data.data
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
