<template>
  <header class="header">
    <div class="header__add">
      <nav class="header-min">
        <ul>
          <li :class="{ active: this.$route.name === 'homePage' }">
            <router-link :to="{ name: 'homePage' }">
              {{$t('headerMin.forHome')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'corporates' }">
            <router-link :to="{ name: 'corporates' }">
              {{$t('headerMin.forOffice')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'coverages' }">
            <router-link :to="{ name: 'coverages' }">
              {{$t('headerMin.coverage')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'about' }">
            <router-link :to="{ name: 'about' }">
              {{$t('headerMin.aboutUs')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'contact' }">
            <router-link :to="{ name: 'contact' }">
              {{$t('headerMin.contacts')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'vacancyr' }">
            <router-link :to="{ name: 'vacancyr' }">
              {{ $t('headerMin.vacancy') }}
            </router-link>
          </li>
          <li>
            <a href="https://optikom.speedtestcustom.com" target="_blank">
              Speedtest
            </a>
          </li>
        </ul>
      </nav>

      <nav class="contacts">
        <change-language/>
      <span class="contact">
              <h6>
                <strong> +998 78 777 00 01 </strong>
              </h6>
              <small>
                {{ $t('headerMin.support') }}
              </small>
              <a href="tel:+998787770001"></a>
      </span>

      </nav>
    </div>
    <div class="header__main">
      <div class="header__main-logo">
        <router-link :to="{ name: 'home' }">
          <img src="../../assets/optikom.svg" />
        </router-link>
      </div>
      <div class="header__main-links">
        <ul>
          <li :class="{ active: this.$route.name === 'internet' }">
            <router-link :to="{ name: 'internet' }">
              {{$t('headerLinks.internet')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'tv' }">
            <router-link :to="{ name: 'tv' }">
              {{$t('headerLinks.tv')}}
            </router-link>
          </li>
          <li>
            <a href="https://optimedia.uz" target="_blank">
              {{$t('headerLinks.resources')}}
            </a>
          </li>
          <li
            :class="{
              active:
                this.$route.name === 'news' ||
                this.$route.name === 'newsDetail',
            }"
          >
            <router-link :to="{ name: 'news' }">
              {{$t('headerLinks.saleAndNews')}}
            </router-link>
          </li>
          <li :class="{ active: this.$route.name === 'service' }">
            <router-link :to="{ name: 'service' }">
              {{$t('headerLinks.services')}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header__main-rightBlock">
        <a href="#!" @click="cheackCabinet('https://cabinet.optikom.uz/')">
          <i class="icon-key"></i>
          {{$t('headerLinks.personalCabinet')}}
        </a>
      </div>
    </div>

    <div class="header__mob">
      <div class="header__mob-leftBlock">
        <button @click="openBurger()">
          <i class="icon-burger"></i>
        </button>
      </div>

      <div class="header__mob-logo">
        <router-link :to="{ name: 'home' }">
          <img src="../../assets/optikom.svg" />
        </router-link>
      </div>

      <div class="header__mob-rightBlock">
        <button class="mob-menu" @click="openBurger()">
          <i class="icon-burger"></i>
        </button>
        <button @click="cheackCabinet('https://cabinet.optikom.uz/')">
          <i class="icon-key"></i>
        </button>
      </div>

      <div :class="{ active: burger }" class="header__mob-menu">
        <div class="menu__btn">
          <button class="mob-menu" @click="closeBurger()">
            <i class="icon-close-big"></i>
          </button>
        </div>
        <nav class="main">
          <ul>
            <li :class="{ active: this.$route.name === 'internet' }">
              <router-link :to="{ name: 'internet' }">
                {{ $t('headerLinks.internet') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'tv' }">
              <router-link :to="{ name: 'tv' }">
                {{ $t('headerLinks.tv') }}
              </router-link>
            </li>
            <li>
              <a href="https://optimedia.uz" target="_blank">
                {{ $t('headerLinks.resources') }}
              </a>
            </li>
            <li
              :class="{
                active:
                  this.$route.name === 'news' ||
                  this.$route.name === 'newsDetail',
              }"
            >
              <router-link :to="{ name: 'news' }">
                {{ $t('headerLinks.saleAndNews') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'service' }">
              <router-link :to="{ name: 'service' }">
                {{ $t('headerLinks.services') }}
              </router-link>
            </li>
          </ul>
        </nav>

        <nav class="secondary-nav">
          <ul>
            <li :class="{ active: this.$route.name === 'homePage' }">
              <router-link :to="{ name: 'homePage' }">
                {{ $t('headerMin.forHome') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'corporates' }">
              <router-link :to="{ name: 'corporates' }">
                {{ $t('headerMin.forOffice') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'coverages' }">
              <router-link :to="{ name: 'coverages' }">
                {{ $t('headerMin.coverage') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'about' }">
              <router-link :to="{ name: 'about' }">
                {{ $t('headerMin.aboutUs') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'contact' }">
              <router-link :to="{ name: 'contact' }">
                {{ $t('headerMin.contacts') }}
              </router-link>
            </li>
            <li :class="{ active: this.$route.name === 'vacancy' }">
              <router-link :to="{ name: 'vacancyr' }">
                {{ $t('headerMin.vacancy') }}
              </router-link>
            </li>
            <li>
              <a href="https://optikom.speedtestcustom.com" target="_blank">
                Speedtest
              </a>
            </li>
            <li>
              <change-language/>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import ChangeLanguage from "@/components/changeLanguage";

export default {
  name: "headerMain",
  components: {ChangeLanguage},
  data: function () {
    return {
      logoUrl: "img/optikom.svg",
      burger: false,
    };
  },

  methods: {
    openBurger() {
      this.burger = true;
      document.querySelector("body").classList.add("overflow");
    },
    closeBurger() {
      this.burger = false;
      document.querySelector("body").classList.remove("overflow");
    },
    async checkOptiMan(url) {
      const DATA_FROM_API = await axios.get(
        "https://admin.optikom.uz/api/v1/check"
      );
      let optiman = DATA_FROM_API.data.data.optiMan;
      if (optiman === true) {
        window.location.href = url;
      } else {
        window.location.href = "/optiman";
      }
    },
    async cheackCabinet(url) {
      const DATA_FROM_API = await axios.get(
        "https://admin.optikom.uz/api/v1/check"
      );
      let optiman = DATA_FROM_API.data.data.optiMan;
      if (optiman === true) {
        window.location.href = url;
      } else {
        window.location.href = "/optimanCabinet";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.header {
  &__add {
    padding: 10px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav.header-min {
      ul {
        li {
          &.active {
            a {
              color: $colorBlue;
            }
          }

          a {
            font-weight: 500;
            font-size: 0.8rem;
            line-height: 120%;
            margin-right: 20px;
            color: $colorBlack;
          }
        }
      }
    }
    nav.contacts {
      display: flex;
      .contact {
        position: relative;
      }
      h6 {
        margin: 0;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      position: relative;
      display: flex;
      gap: 10px;
    }
  }

  &__main {
    background-color: $colorWhite;
    display: flex;
    align-items: stretch;
    justify-content: center;

    &-logo {
      padding: 18px 20px;
      width: 20%;
      display: flex;
      justify-content: center;
      border: 1px solid $borderColor;
      align-items: center;

      a {
        display: flex;
        justify-content: center;
      }

      img {
        width: 80%;
      }
    }

    &-links {
      padding: 18px 20px;
      width: 60%;
      display: flex;
      justify-content: center;
      border: 1px solid $borderColor;
      align-items: center;
      border-left: none;
      border-right: none;

      ul {
        li {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            a {
              &::after {
                transform: scaleX(1);
              }
            }
          }

          a {
            font-weight: 700;
            font-size: 1rem;
            line-height: 128.9%;
            letter-spacing: -0.025em;
            color: $colorBlack;
            position: relative;
            display: inline-flex;
            @extend .transition;

            &:hover {
              &::after {
                transform: scaleX(1);
                transform-origin: left;
              }
            }

            &::after {
              content: "";
              display: block;
              height: 4px;
              border-radius: 4px;
              transform: scaleX(0);
              transform-origin: 100% 0;
              transition: transform 0.4s cubic-bezier(0.27, 0.17, 0.05, 0.87);
              background: $colorBlue;
              opacity: 1;
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              z-index: 333;
            }
          }
        }
      }
    }

    &-rightBlock {
      padding: 18px 20px;
      width: 20%;
      display: flex;
      justify-content: center;
      border: 1px solid $borderColor;
      align-items: center;

      a {
        padding: 12px 15px;
        border-radius: 15px;
        border: 1px solid $borderColor;
        color: #000;
        font-weight: 600;
        font-size: 1rem;
        line-height: 120%;
        @extend .transition;
        display: inline-flex;
        align-items: center;

        i {
          margin-right: 5px;
        }

        &:hover {
          border-color: $colorBlue;
        }

        &:active {
          @extend .scaleMin;
        }
      }
    }
  }

  &__mob {
    background-color: $colorWhite;
    display: none;
    padding: 15px 20px;

    &-menu {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      background: $bodyColor;
      position: fixed;
      z-index: 99;
      left: 0;
      top: 0;
      width: 250px;
      height: 100vh;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
      transform: translateX(-300px);
      @extend .transition;

      &.active {
        transform: translateX(0);
      }

      .menu__btn {
        padding: 0 20px 20px 20px;
        border-bottom: 1px solid $borderColor;
        margin-bottom: 10px;

        button {
          background: transparent;
          width: 60px;
          height: 60px;
          border-radius: 15px;
          border: 1px solid $borderColor;
          color: #000;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 120%;
          @extend .transition;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin-right: 0px;
          }

          &:hover {
            border-color: $colorBlue;
          }

          &:active {
            @extend .scaleMin;
          }
        }
      }

      nav.main {
        margin-bottom: 20px;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 20px;

          li {
            &.active {
              a {
                &::after {
                  transform: scaleX(1);
                }
              }
            }

            a {
              font-weight: 700;
              font-size: 1rem;
              line-height: 128.9%;
              letter-spacing: -0.025em;
              position: relative;
              display: flex;
              padding: 15px 0;
              color: $colorBlack;
              border-bottom: 1px solid $borderColor;
              @extend .transition;
              margin-bottom: 5px;

              &:hover {
                &::after {
                  transform: scaleX(1);
                  transform-origin: left;
                }
              }

              &::after {
                content: "";
                display: block;
                height: 4px;
                border-radius: 4px;
                transform: scaleX(0);
                transform-origin: 100% 0;
                transition: transform 0.4s cubic-bezier(0.27, 0.17, 0.05, 0.87);
                background: $colorBlue;
                opacity: 1;
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
                z-index: 333;
              }
            }
          }
        }
      }

      .secondary-nav {
        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 20px;

          li {
            &.active {
              a {
                color: $colorBlue;
              }
            }

            a {
              display: flex;
              font-weight: normal;
              font-size: 0.9rem;
              border: none;
              margin-bottom: 0;
              color: $colorBlack;
              padding: 5px 0;

              &:hover {
                transform: translate(0);
              }
            }
          }
        }
      }
    }

    &-logo {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 30px;
      border-left: none;
      border-right: none;

      a {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          max-width: 250px;
        }
      }
    }

    &-rightBlock,
    &-leftBlock {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;

      .mob-menu {
        display: none;
      }

      button {
        background: transparent;
        width: 60px;
        height: 60px;
        border-radius: 15px;
        border: 1px solid $borderColor;
        color: #000;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 120%;
        @extend .transition;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin-right: 0px;
        }

        &:hover {
          border-color: $colorBlue;
        }

        &:active {
          @extend .scaleMin;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .header__main {
      &-logo {
        width: 26% !important;
        padding: 15px;
      }

      &-links {
        width: 48% !important;
        padding: 15px;
      }

      &-rightBlock {
        width: 26% !important;
        padding: 15px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .header__main,
    .header__add {
      display: none !important;
    }
    .header__mob {
      display: flex !important;
      align-items: stretch;
      justify-content: center;
    }
  }

  @media screen and (max-width: 700px) {
    .header__main,
    .header__add {
      display: none !important;
    }
    .header__mob {
      display: flex !important;
      align-items: stretch;
      justify-content: center;

      &-leftBlock {
        width: 20%;
      }

      &-logo {
        width: 80%;
      }

      &-rightBlock {
        width: 20%;
      }
    }
  }
}
</style>
