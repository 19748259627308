<template>
  <article class="article" tabindex="0">
    <div :style='{ backgroundImage: `url("${film.poster}")` }' class="article__poster">
      <div class="article__poster-info">
        <div class="info-elements-rank">
          <span>{{ film.age }}</span>
        </div>
        <div class="info-elements-rank">
          <span>fullHD</span>
        </div>

      </div>
      <div class="article__poster-info poster__info-bottom">
        <div class="info-elements-rank">
          <span>{{ film.year }}</span>
        </div>

      </div>
      <div class="article__poster-button">

        <button class="btn-glass">
          <svg fill="none" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 11.5V7.495C1.5 2.5225 5.02125 0.486254 9.33 2.9725L12.8063 4.975L16.2825 6.9775C20.5912 9.46375 20.5912 13.5363 16.2825 16.0225L12.8063 18.025L9.33 20.0275C5.02125 22.5138 1.5 20.4775 1.5 15.505V11.5Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                stroke-width="3"/>
          </svg>
        </button>

      </div>

      <div class="article__poster-backdrop">

      </div>
    </div>

    <button
        class="article__link"
        @click="checkOptiMan(film.url)">

    </button>

    <div class="article__title">
      <h2>
        {{ film.name }}
      </h2>
      <p>{{ film.orig_name }}
      </p>
    </div>
  </article>
</template>

<script>
import axios from "axios";

export default {
  name: "filmItemOnly",
  props: {
    film: {
      type: Array,
      default: function () {
        return [];
      }
    },


  },
  methods: {
    async checkOptiMan(url) {
      const DATA_FROM_API = await axios.get("https://admin.optikom.uz/api/v1/check");
      let optiman = DATA_FROM_API.data.data.optiMan
      if (optiman === true) {

        window.location.href = url


      } else {
        window.location.href = '/optiman'
      }

    }
  }

};
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.article {
  margin-bottom: 40px;


  &:hover {


  }


  &__poster {
    border-radius: 20px !important;


    &-backdrop {
      border-radius: 20px !important;
    }
  }

  &__title {
    padding: 0 5px 0 5px;

    h2 {
      font-size: 18px;
      margin: 10px 0 0px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all .2s ease-in-out;
      color: $colorWhite;
    }

    p {
      font-size: 12px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.6;
    }
  }
}


.article {
  overflow: hidden;
  border-radius: 12px;
  position: relative;

  &:hover, &:focus {
    transform: scale(1.1);
    position: relative;
    z-index: 9;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.35);

    .article__poster {
      .article__poster-title {
        h2, p {
          transform: translate(0);
        }
      }

      .article__poster-button {
        transform: scale(1);

        button {
          opacity: 1;
        }
      }
    }

    .article__poster-backdrop {
      opacity: 1;
    }

    &:active, &:focus {
      transform: scale(0.9)
    }
  }

  transition: all 0.4s cubic-bezier(.27, .17, .05, .87);

  .article__link {
    background: transparent;
    border: transparent;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  &__poster {
    position: relative;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;

    &-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 10px 0 10px;
      z-index: 9;
      display: flex;
      justify-content: space-between;

      .info-elements-rank {
        display: inline-flex;
        background: linear-gradient(68deg, rgba(74, 74, 74, 0.6) 0%, rgba(0, 0, 0, 0.3) 100%);
        color: #fff;
        font-size: 14px;
        padding: 4px 8px;
        //backdrop-filter: blur(4px);
        border-radius: 6px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-top: 0.1px solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.1px solid rgba(255, 255, 255, 0.3);

        svg {
          fill: #FF6600;
        }

        span:nth-child(1) {
          display: inline-flex;

        }
      }


    }

    .poster__info-bottom {
      top: auto;
      bottom: 0 !important;
      padding: 0px 10px 20px 10px;
      display: flex;
      justify-content: flex-end;

    }

    &-title {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
      padding: 20px 10px 10px 10px;
      width: 100%;

      h2, p {
        transition: all 0.3s cubic-bezier(.27, .17, .05, .87);

      }

      h2 {
        font-size: 18px;
        margin-bottom: 5px;
        transform: translateY(200px);


      }

      p {
        font-size: 12px;
        transform: translateY(200px);

      }
    }

    &-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;


      button {
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
        border: 0.1px solid transparent;
        transition: all 0.4s cubic-bezier(.27, .17, .05, .87);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 20%;

        svg {
          width: 60%;
        }

      }
    }

    &-backdrop {
      opacity: 0;
      transition: opacity 0.1s cubic-bezier(.27, .17, .05, .87);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(330.83deg, rgba(35, 35, 35, 0.80) 0%, rgba(35, 35, 35, 0) 100%);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(6px);
      border-radius: 10px;

    }


  }

  &__title {
    padding: 0 5px 0 5px;

    h2 {
      font-size: 18px;
      margin: 10px 0 0px 0;

    }

    p {
      font-size: 12px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.6;
    }
  }


}


img {
  width: 100%;
}

@media screen and (max-width: 1750px) {
  .article__poster {
    height: 280px !important;
  }
}

@media screen and (max-width: 1650px) {
  .article__poster {
    height: 250px !important;
  }
}

@media screen and (max-width: 1050px) {
  .article__poster {
    height: 300px !important;
  }
}

@media screen and (max-width: 750px) {
  .article__poster {
    height: 320px !important;

    .info-elements-rank {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 550px) {
  .article__poster {
    height: 250px !important;

  }
}

@media screen and (max-width: 400px) {
  .article__poster {
    height: 220px !important;

    .info-elements-rank {
      padding: 5px 8px;
      font-size: 12px;
      border-radius: 5px;
    }
  }
}


</style>