<template>
  <main>
    <cursor-fx
        color-hover="#fff"
    ></cursor-fx>
    <headerMain/>
    <bigSlider/>
    <newsItem/>


    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import bigSlider from "@/components/bigSlider";
import footerMain from "@/components/rp-blocks/footer";
import newsItem from "@/components/news-item";


export default {
  name: 'news',
  components: {
    headerMain, bigSlider, footerMain, newsItem

  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Акции и Новости'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
