<template>
  <section class="canal">

    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('tv.title') }}
        </h2>
      </div>

      <div class="canal__block">
        <div v-for="(canalItem, index) in canals" :key="index" class="canal__item">
          <div class="canal__item-poster">
            <img :alt="canalItem.name" :src=canalItem.image>
          </div>

          <div class="canal__item-title">
            <h3>
              {{ canalItem.name }}
            </h3>
          </div>
          <a href="https://optimedia.uz" class="canal__link">

          </a>
        </div>
      </div>


      <div v-show="currentPage<lastPage" class="canal__btn">
        <button class="btn white big" @click="changeCrntPage" data-cursor-hover>
          {{ $t('tv.more') }}
        </button>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

export default {

  name: "tv-canals",
  data: function () {
    return {
      canals: [],
      currentPage: 1,
      lastPage: 0,
    }
  },
  methods: {
    async changeCrntPage() {
      this.currentPage += 1;
      let DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/tv/getList?page=${this.currentPage}`);
      let data = DATA_FROM_API.data.data.list;
      for (let i = 0; i < data.length; i++) {
        this.canals.push(data[i]);
      }
    }
  },
  async created() {

    const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/tv/getList?page=${this.currentPage}`);
    this.lastPage = DATA_FROM_API.data.data.lastPage
    this.canals = DATA_FROM_API.data.data.list;
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.canal {
  padding: 100px 0;

  &__block {
    display: flex;
    margin-top: 60px;
    gap: 30px 2%;
    flex-wrap: wrap;

    //justify-content: space-between;


    .canal__item {
      width: 15%;
      background: $colorWhite;
      padding: 20px;
      border-radius: 25px;
      position: relative;

      .canal__link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      @extend .transition;

      &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      }

      &:active {
        @extend .scaleMin
      }

      &-poster {
        img {
          width: 100%;
          border-radius: 12.5px;
        }

        border: 1px solid $bodyColor;
        border-radius: 12.5px;
        margin-bottom: 15px;
      }

      &-title {
        h3 {
          font-weight: 700;
          font-size: 1.1rem;
          line-height: 120%;
          text-align: center;
          color: $colorBlack;
          margin-bottom: 0;
        }
      }

    }

  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  @media screen and (max-width: 1200px) {

    .canal__item {
      width: 18.4% !important;
    }

  }
  @media screen and (max-width: 1000px) {

    .canal__item {
      width: 23.5% !important;
    }

  }
  @media screen and (max-width: 800px) {

    .canal__item {
      width: 32% !important;
    }

  }

  @media screen and (max-width: 600px) {

    .canal__item {
      width: 49% !important;

      &-title {
        h3 {
          font-weight: 700;
          font-size: 0.9rem;
          line-height: 120%;
          text-align: center;
          color: $colorBlack;
          margin-bottom: 0;
        }
      }
    }

  }
  @media screen and (max-width: 350px) {

    .canal__block {
      gap: 30px 0 !important;
    }

    .canal__item {
      width: 100% !important;

    }

  }

}

</style>