<template>
  <section>
    <vs-dialog v-model="active1" not-close overflow-hidden>
      <template #header>
        <h3>
          Подключиться
        </h3>
      </template>
      <div class="con-content">
        <div class="form">
          <input v-model="name" placeholder="Введите ваше имя" required type="text">
          <input v-model="phone" v-maska="'+998(##)-###-##-##'" placeholder="Введите номер телефона" required
                 type="text">

          <button class="btn" data-cursor-hover @click="postTicket()">
            Оставить заявку
          </button>
        </div>
      </div>
    </vs-dialog>
    <vs-dialog v-model="active2" not-center width="550px">
      <template #header>
        <h4 style="margin-top: 20px">
          {{ mes }}
        </h4>
      </template>


      <div class="con-content">
        <p>
          Пожалуйста запомните номер вашей заявки
        </p>
      </div>

      <template #footer>
        <div class="con-footer" style="display: flex;justify-content: center">
          <vs-button class="btn body-c" transparent @click="active2=false, active=false">
            Закрыть
          </vs-button>
        </div>
      </template>
    </vs-dialog>
    <div ref="testSwiper" class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->

        <div v-for="(tariffItem, index) of tariffData.tariffs" :key="index" class="swiper-slide">
          <new-tarif-item
              :tariff-item="tariffItem"
              :tariffName="tariffData.name"
              :tariffIndex="index"
              @sendTicket="sendTicket"
          />
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper";
import NewTarifItem from "@/components/newTarifItem";
import {maska} from 'maska'
import axios from "axios";

export default {
  data: function () {
    return {
      name: '',
      phone:'',
      mes: '',
      active1: false,
      active2: false,
      selectedTariff: []
    }
  },
  components: {NewTarifItem},
  props: {
    tariffData: {
      type: Array,
    },
    componentKey: {
      type: String
    }
  },
  directives: {maska},
  mounted() {
    const swiper = new Swiper(this.$refs.testSwiper, {
      spaceBetween: 30,
      breakpoints: {
        0: {
          slidesPerView: 1.05,
          spaceBetween: 15
        },
        400: {
          slidesPerView: 1.2,
          spaceBetween: 15
        },
        800: {
          slidesPerView: 2.2,
        },
        1400: {
          slidesPerView: 4,
        }
      }


    })

    console.log(swiper)
  },
  methods: {
    sendTicket(tariff) {
      this.selectedTariff = tariff
      this.active1 = true
      console.log(tariff)
    },
    postTicket(e) {
      axios.post(`https://admin.optikom.uz/api/v1/web/send-ticket?name=${this.name}&phone=${this.phone}&tariff_name=${this.selectedTariff.name}&daytime_speed=${this.selectedTariff.daytimeSpeed}&period=${this.selectedTariff.period}&price=${this.selectedTariff.price}`,)
          .then(response => {
            this.mes = response.data.message
            this.active2 = true
            this.active1 = false
          })
          .catch(function (error) {
            console.log(error);
          });

      e.preventDefault();


    },
  }
}
</script>

<style  scoped lang="scss">
@import "src/scss/vars";
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $colorWhite;
  padding: 50px;
  border-radius: 50px;
  max-width: 600px;

  form {
    width: 100%;

  }

  input {
    padding: 25px 30px;
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #DDDDDE;
    border-radius: 25px;
  }

  ::placeholder {
    color: $colorBlack;
  }

  textarea {
    width: 100%;
    padding: 25px 30px;
    border: 1px solid #DDDDDE;
    border-radius: 25px;
    margin-bottom: 30px;
  }

  button {
    width: 100%;
    padding: 25px;
    text-align: center;
    justify-content: center;
  }
}
</style>