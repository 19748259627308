import en from './en'
import ru from './ru'
import uz from './uz'

const lang = localStorage.getItem('lang')
export const defaultLocale = 'ru'
if (lang === null) {
    localStorage.setItem('lang', defaultLocale)
}
console.log()

export const languages = {
    en, ru, uz
}