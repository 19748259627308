<template>
  <section class="contact">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('contact.title') }}
        </h2>

      </div>
      <div class="contact__block">
        <div class="contact__item">
          <div class="contact__item-icon">
            <span>
              <i class="icon-fi-br-marker"></i>
            </span>
          </div>
          <div class="contact__item-title">
            <h3>
              {{ $t('contact.contacts.addressTitle') }}
            </h3>
            <p>
              {{ $t('contact.contacts.addressBody') }}
            </p>
          </div>
        </div>
        <div class="contact__item">
          <div class="contact__item-icon">
            <span>
              <i class="icon-fi-br-headset  "></i>
            </span>
          </div>
          <div class="contact__item-title">
            <h3>
              {{ $t('contact.contacts.supportTitle') }}
            </h3>
            <p>
              {{ $t('contact.contacts.supportBody') }}
            </p>
          </div>
        </div>
        <div class="contact__item">
          <div class="contact__item-icon">
            <span>
              <i class="icon-fi-br-at"></i>
            </span>
          </div>
          <div class="contact__item-title">
            <h3>
              {{ $t('contact.contacts.emailTitle') }}
            </h3>
            <p>
              {{ $t('contact.contacts.emailBody') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <contactForm/>
    <contactMap/>

  </section>
</template>

<script>
import contactForm from "@/components/contact-form";
import contactMap from "@/components/contact-map";


export default {
  name: "contact-body",
  data: function () {
    return {}
  },
  components: {
    contactForm, contactMap
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.contact {
  padding-top: 100px;

  .section__title {
    margin-bottom: 40px;
  }

  &__block {
    display: flex;
    gap: 30px 2%;
    flex-wrap: wrap;

    .contact__item {
      width: 32%;
      background: $colorWhite;
      padding: 20px 30px;
      display: flex;
      gap: 0 5%;
      border-radius: 30px;

      &-icon {
        span {
          display: inline-flex;
          width: 80px;
          height: 80px;
          background: $colorLightBlue;
          justify-content: center;
          align-items: center;
          border-radius: 20px;

          i {
            color: $colorBlue;
            font-size: 2rem;
          }
        }

      }

      &-title {
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 132%;
          letter-spacing: -0.025em;
          margin-bottom: 0;
        }

        p {

          font-weight: 400;
          font-size: 0.9rem;
          line-height: 156.5%;
          margin: 0;
          letter-spacing: -0.025em;

        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .contact__block {

      .contact__item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;

        &-icon {
          margin-bottom: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .contact__block {

      .contact__item {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;

        &-icon {
          margin-bottom: 15px;
        }
      }
    }
  }

}
</style>