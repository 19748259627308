<template>
  <section class="about__form">
    <vs-dialog v-model="active" not-center width="550px">
      <template #header>
        <h4 style="margin-top: 20px">
          {{ $t('contact.thanksApplication') }}
        </h4>
      </template>

      <template #footer>
        <div class="con-footer" style="display: flex;justify-content: center">
          <vs-button class="btn body-c" transparent @click="active2=false, active=false">
            {{ $t('utils.close') }}
          </vs-button>
        </div>
      </template>
    </vs-dialog>
   <div class="container-fluid">
     <div class="section__title center">
       <h2>
         {{ $t('utils.sendApplication') }}
       </h2>
     </div>


     <div class="about__form-form">
       <div class="form">
         <input v-model="name" mask="\+\1 (111) 111-1111" :placeholder="$t('utils.input.name')" required type="text">
         <input v-model="phone" v-maska="'+998(##)-###-##-##'" :placeholder="$t('utils.input.phone')" required
                type="text">
         <textarea v-model="message" :placeholder="$t('utils.input.message')" required>
        </textarea>
         <button class="btn" data-cursor-hover @click="form">
           {{ $t('utils.sendApplication') }}
         </button>
       </div>

     </div>
   </div>

  </section>
</template>

<script>
import {maska} from "maska";
import axios from "axios";

export default {
  name: "contact-form",
  data: function () {
    return {
      illustUrl: 'img/support.png',
      name: '',
      phone: '',
      message: '',
      active: false,
      input1: '',
      input2: '',
      mes: ''

    }
  },
  directives: {maska},
  methods: {
    form() {
      axios.post('https://admin.optikom.uz/api/v1/web/postTicket', {
        name: this.name,
        phone: this.phone,
        message: this.message,
        tariff_id: null,
      }, {
        headers: {
          lang: 'ru'
        }
      })
          .then(response => (this.mes = response.data.message, this.active = true))
          .catch(function (error) {
            console.log(error);
          });

      console.log(this.mes)


    },

  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.about__form {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: -150px;
  position: relative;
  z-index: 2;
  .section__title {
    margin-bottom: 40px;
  }
  &-form {
    display: flex;
    justify-content: center;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $colorWhite;
  padding: 50px;
  border-radius: 50px;
  max-width: 600px;
  justify-content: center;
  box-shadow: 0 0 40px rgba(0,0,0,0.1);

  input {
    padding: 25px 50px;
    margin-bottom: 30px;
    border: 1px solid #DDDDDE;
    border-radius: 25px;
  }

  ::placeholder {
    color: $colorBlack;
  }

  textarea {
    padding: 25px 50px;
    border: 1px solid #DDDDDE;
    border-radius: 25px;
    margin-bottom: 30px;
  }

  button {
    padding: 25px;
    text-align: center;
    justify-content: center;
  }
}

</style>