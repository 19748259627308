<template>
  <section class="v-detail">
    <vs-dialog v-model="active" not-close overflow-hidden>
      <template #header>
        <h3>
          {{ $t('utils.respond') }}
        </h3>
      </template>
      <div class="con-content">
        <div class="form">

          <form id="uploadForm" enctype="multipart/form-data" @submit="uploadFiles">
            <input v-model="name" :placeholder="$t('utils.input.name')" required type="text">
            <input v-model="phone" v-maska="'+998(##)-###-##-##'" :placeholder="$t('utils.input.phone')" required
                   type="text">

            <label for="files"> {{ $t('utils.respond') }}</label>

            <input id="files" accept="image/*,.pdf,.doc,.docx,application/msword" multiple name="files" type="file"><br>


            <button class="btn" data-cursor-hover @click="uploadFiles">
              {{ $t('utils.input.createApplication') }}
            </button>
          </form>


        </div>
      </div>
    </vs-dialog>
    <vs-dialog v-model="active2" not-center width="550px">
      <template #header>
        <h4 style="margin-top: 20px">
          {{ $t('utils.thanks') }}
        </h4>
      </template>


      <div class="con-content">
        <p>
          {{ $t('utils.wait') }}
        </p>
      </div>

      <template #footer>
        <div class="con-footer" style="display: flex;justify-content: center">
          <vs-button class="btn body-c" transparent @click="active2=false, active=false">
            {{ $t('utils.respond') }}
          </vs-button>
        </div>
      </template>
    </vs-dialog>
    <div class="container-fluid">
      <div class="v-detail__block">

        <div class="v-detail-vacancy vacancy">
          <h1>
            {{ title }}
          </h1>
          <div class="body" v-html="vacancy_text">

          </div>
          <div class="vacancy__btn">
            <button class="btn big blue" @click="active=!active">
              {{ $t('utils.respond') }}
            </button>
          </div>


        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {maska} from "maska";
import axios from "axios";

export default {
  name: "vacancyDetail-body",
  props: ['vacancy_text', 'title', 'vacancy_id'],
  data: function () {
    return {
      active: false,
      active2: false,
      name: '',
      phone: '',
      files: [],
    }
  },
  directives: {maska},
  methods: {


    previewFiles() {
      this.files = this.$refs.myFiles.files
    },

    uploadFiles(e) {
      e.preventDefault();
      var s = this
      const data = new FormData(document.getElementById('uploadForm'))
      var imagefile = document.querySelector('#files')
      data.append('file', imagefile.files[0])
      data.append('name', s.name)
      data.append('phone', s.phone)
      axios.post('https://admin.optikom.uz/api/v1/web/postVacancy', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            response,
                this.active2 = true;
          })
          .catch(error => {
            error
          })

    }
  },


}
</script>

<style lang="scss" scoped>
.v-detail {
  padding: 100px 0;

  &__block {
    display: flex;

    .vacancy {
      width: 50%;

      ul {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .vacancy {
      width: 100%;
    }

  }
}

</style>