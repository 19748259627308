<template>
  <section class="home">
    <img alt="" class="img-fluid" src="../assets/smarthome.jpg">

    <div class="first__element">
      <div class="pulse_holder">
        <div class="pulse_marker">
          <div class="pulse_ray pulse_rays2"></div>
          <div class="pulse_ray pulse_rays3"></div>
          <div class="pulse_ray pulse_rays4"></div>
        </div>
      </div>
      <img alt="first item" src="../assets/second_item.png">
    </div>
    <div class="second__element">
      <div class="pulse_holder">
        <div class="pulse_marker">
          <div class="pulse_ray pulse_rays2"></div>
          <div class="pulse_ray pulse_rays3"></div>
          <div class="pulse_ray pulse_rays4"></div>
        </div>
      </div>
      <img alt="second item" src="../assets/first_item.png">
    </div>


  </section>
</template>

<script>
export default {
  name: "homeBanner"
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.home {
  padding: 0 !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  img {
    width: 100%;
  }

  .first__element {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 51.5%;
    right: 28%;

    img {
      width: 60px;
      display: flex;
      //position: absolute;

      @extend .transition;
      @media screen and (max-width: 1400px) {
        width: 2.5rem;

      }
      @media screen and (max-width: 950px) {
        .pulse {
          width: 0.2rem !important;
        }
        width: 2.0rem;

      }
      @media screen and (max-width: 800px) {
        width: 1.7rem;

      }
      @media screen and (max-width: 650px) {
        width: 1.5rem;

      }
      @media screen and (max-width: 580px) {
        width: 1.2rem;

      }
      @media screen and (max-width: 500px) {
        right: 28%;
        width: 1rem;

      }
      @media screen and (max-width: 430px) {
        right: 28%;
        width: 0.9rem;

      }
      @media screen and (max-width: 380px) {
        right: 28%;
        width: 0.8rem;

      }
    }

    @extend .transition;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:active {

      transform: scale(0.9);
    }

    @keyframes pulse {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      8% {
        transform: scale(0);
        opacity: 0;
      }
      15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
      }
      30% {
        transform: scale(0.5);
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(1);
      }
    }


    .pulse_holder {
      display: block;
      position: absolute;
      top: 40%;

      .pulse_marker {
        width: 0.3rem;
        height: 0.3rem;
        background: rgba(84, 99, 255, 1);
        border-radius: 28px;
      }

      .pulse_ray {
        margin: 0 auto;
        border-radius: 100px;
        background-color: transparent;
        border: 2px solid rgba(84, 99, 255, 1)



      }

      .pulse_rays2 {
        position: absolute;
        right: -0.75rem;
        top: -0.75rem;
        z-index: 10;
        width: 1.8rem;
        height: 1.8rem;
        opacity: 0.8;
        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.2s;


      }

      .pulse_rays3 {
        position: absolute;
        right: -1.65rem;
        top: -1.65rem;
        z-index: 10;
        width: 3.6rem;
        height: 3.6rem;
        opacity: 0.5;

        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.1s;



      }
      .pulse_rays4 {
        position: absolute;
        right: -2.3rem;
        top: -2.3rem;
        z-index: 10;
        width: 5rem;
        height: 5rem;
        opacity: 0.3;

        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.3s;


      }
    }


  }

  .second__element {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 20%;
    left: 3%;
    .pulse_holder {
      display: block;
      position: absolute;
      top: 40%;

      .pulse_marker {
        width: 0.3rem;
        height: 0.3rem;
        background: rgba(84, 99, 255, 1);
        border-radius: 28px;
      }

      .pulse_ray {
        margin: 0 auto;
        border-radius: 100px;
        background-color: transparent;
        border: 2px solid rgba(84, 99, 255, 1)



      }

      .pulse_rays2 {
        position: absolute;
        right: -0.75rem;
        top: -0.75rem;
        z-index: 10;
        width: 1.8rem;
        height: 1.8rem;
        opacity: 0.8;
        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.2s;


      }

      .pulse_rays3 {
        position: absolute;
        right: -1.65rem;
        top: -1.65rem;
        z-index: 10;
        width: 3.6rem;
        height: 3.6rem;
        opacity: 0.5;

        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.1s;



      }
      .pulse_rays4 {
        position: absolute;
        right: -2.3rem;
        top: -2.3rem;
        z-index: 10;
        width: 5rem;
        height: 5rem;
        opacity: 0.3;

        animation: pulse 3s ease-in-out infinite;
        animation-delay: 0.3s;


      }
    }
    @media screen and (max-width: 1400px) {
      .pulse {
        width: 1.2rem !important;
        height: 1.2rem !important;;
      }


    }
    @media screen and (max-width: 650px) {
      .pulse {
        width: 0.8rem !important;
        height: 0.8rem !important;;
      }


    }
    @media screen and (max-width: 500px) {
      .pulse {
        width: 0.5rem !important;
        height: 0.5rem !important;;
      }


    }

    img {
      width: 60px;
      display: flex;
      //position: absolute;

      @extend .transition;
      @media screen and (max-width: 1400px) {
        width: 2rem;

      }
      @media screen and (max-width: 950px) {
        .pulse {
          width: 0.2rem !important;
        }
        width: 2.0rem;

      }
      @media screen and (max-width: 800px) {
        width: 1.7rem;

      }
      @media screen and (max-width: 650px) {
        width: 1.5rem;

      }
      @media screen and (max-width: 580px) {
        width: 1.2rem;

      }
      @media screen and (max-width: 500px) {
        right: 28%;
        width: 1rem;

      }
      @media screen and (max-width: 430px) {
        right: 28%;
        width: 0.9rem;

      }
      @media screen and (max-width: 380px) {
        right: 28%;
        width: 0.8rem;

      }
    }

    @extend .transition;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:active {

      transform: scale(0.9);
    }

    .pulse {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      z-index: 99;
      top: 35%;
    }
  }


  .pulse {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: $colorBlue;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(84, 99, 255, 0.4);
    animation: pulse 2s infinite;

    @media screen and (max-width: 950px) {
      width: 1.2rem;
      height: 1.2rem;

    }
    @media screen and (max-width: 800px) {
      width: 0.9rem;
      height: 0.9rem;

    }
    @media screen and (max-width: 600px) {
      width: 0.6rem;
      height: 0.6rem;

    }
    @media screen and (max-width: 400px) {
      width: 0.4rem;
      height: 0.4rem;

    }
  }


  @keyframes pulse {
    0% {
      transform: scale(0.8);
      box-shadow: 0 0 0 0 rgba(84, 99, 255, 0.4);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(84, 99, 255, 0);
    }
    100% {
      transform: scale(0.8);
      box-shadow: 0 0 0 0 rgba(84, 99, 255, 0);
    }
  }
}

</style>