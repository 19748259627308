<template>
  <main>
    <headerMain />
    <section class="section">
      <div class="container-fluid">
        <div class="big_text text">
          <h1>Доступ закрыт</h1>
          <p>
            Личный кабинет доступен только в сети Optikom или через официальное
            приложение
          </p>
          <router-link to="/"> Главная </router-link>
        </div>
        <div class="small_texts">
          <p>Интернет</p>
          <p>Умный дом</p>
          <p class="text_3">Безопасность</p>
          <p class="text_4">IP Телевидение</p>
          <p class="text_5">P Телефония</p>
          <p class="text_6">OPTIKOM</p>
          <p class="text_7">OPTIMEDIA</p>
          <p class="text_8">OPTIKOM</p>
          <p class="text_9">Медиа портал</p>
          <p class="text_10">Co-location</p>
          <p class="text_11">Монтажные работы</p>
          <p class="text_12">Безопасность</p>
          <p class="text_13">Умный дом</p>
        </div>
      </div>
    </section>

    <section>
      <div class="flex social-btns">
        <a
          class="app-btn blu flex vert"
          href="https://apps.apple.com/us/app/my-optikom/id1630306585"
        >
          <i class="icon-icons8-app-store"></i>
          <p>
            Скачать через <br />
            <span class="big-txt">App Store</span>
          </p>
        </a>

        <a
          class="app-btn blu flex vert"
          href="https://play.google.com/store/apps/details?id=uz.optikom.app"
        >
          <i class="icon-icons8-google-play"></i>
          <p>
            Скачать через <br />
            <span class="big-txt">Google Play</span>
          </p>
        </a>
      </div>
    </section>
    <footerMain />
  </main>
</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";

export default {
  name: "404",
  components: {
    headerMain,
    footerMain,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "OPTIKOM",
    // all titles will be injected into this template
    titleTemplate: "%s | Доступ Закрыт",
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.social-btns {
  p {
    margin-bottom: 0;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  &-btn {
    padding: 8px 20px;
    display: inline-flex;
    //width: 45%;
    max-width: 250px;
    color: #fff;
    margin: 20px 10px;
    text-align: left;
    border-radius: 5px;
    text-decoration: none;
    font-family: "Lucida Grande", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    &.blu {
      background-color: #101010;
      transition: background-color 0.25s linear;
      &:hover {
        background-color: #454545;
      }
    }
    i {
      width: 20%;
      text-align: center;
      font-size: 28px;
      margin-right: 15px;
    }
    .big-txt {
      font-size: 17px;
      text-transform: capitalize;
    }
  }
}

.section {
  background: $bodyColor;
  color: #fff;
  overflow: hidden;
  //height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big_text {
  display: grid;
  place-items: center;
  padding: 2rem;
}

.big_text h1 {
  font-size: 4rem;
  transform: translatez(20rem);
  color: $colorBlack;
  text-align: center;
}

.color_blue {
  color: $colorBlue;
  text-shadow: none;
  animation: visible 2s infinite;
}

.big_text p {
  letter-spacing: 1px;
  color: $colorBlack;
  text-align: center;
}

a {
  color: $colorBlue;
}

.small_texts {
  display: flex;
  justify-content: space-around;
}

.small_texts p {
  color: $colorBlack;
  animation: float 8s infinite;
  transform: translatey(4rem);
  opacity: 0;
}

@keyframes float {
  0% {
    transform: translatey(0);
    opacity: (0);
  }
  50% {
    opacity: (0.8);
  }
  90% {
    opacity: (0.3);
  }
  100% {
    transform: translatey(-40rem);
    opacity: (0);
  }
}

@keyframes visible {
  0% {
    opacity: (1);
  }
  20% {
    opacity: (0.5);
  }
  50% {
    opacity: (0.3);
  }
  100% {
    opacity: (0.9);
  }
}

.small_texts p:nth-child(1) {
  animation-delay: 2.2s;
}

.small_texts p:nth-child(2) {
  animation-delay: 4s;
}

.small_texts p:nth-child(3) {
  animation-delay: 7s;
}

.small_texts p:nth-child(4) {
  animation-delay: 5.5s;
}

.small_texts p:nth-child(5) {
  animation-delay: 6.78s;
}

.small_texts p:nth-child(6) {
  animation-delay: 4.9s;
}

.small_texts p:nth-child(7) {
  animation-delay: 2.9s;
}

.small_texts p:nth-child(8) {
  animation-delay: 6s;
}

.small_texts p:nth-child(9) {
  animation-delay: 2.5s;
}

.small_texts p:nth-child(10) {
  animation-delay: 3.8s;
}

.small_texts p:nth-child(11) {
  animation-delay: 6s;
}

.small_texts p:nth-child(12) {
  animation-delay: 7s;
}

.small_texts p:nth-child(13) {
  animation-delay: 2.2s;
}

@media screen and (max-width: 500px) {
  .big_text {
    padding: 0 !important;
  }
}
</style>
