<template>
  <section class="news">

    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          <router-link to="">
            {{ $t('news.title') }}
          </router-link>
        </h2>
      </div>

      <div class="news__btn">
        <span class="btn-toggle">
          <button :class="{ blue: promo, 'white': news }" class="btn" @click="changeType">
               {{ $t('news.sale') }}
          </button>
          <button :class="{ blue: news, 'white': promo }" class="btn" @click="changeType">
             {{ $t('news.newsTitle') }}
          </button>
        </span>
      </div>

      <div class="news__block">
        <div v-for="(newsItem, index) in newsItems" :key="index" class="news__item" v-scroll-reveal="{ delay: 300 }">
          <div class="news__item-poster">
            <img :alt="newsItem.name" :src=newsItem.poster>
          </div>

          <div class="news__item-title">
            <h3>
              {{ newsItem.name }}
            </h3>
            <p>
              {{ newsItem.text }}
            </p>
          </div>
          <router-link :to="{name: 'newsDetail', params: {id: newsItem.id, slug: newsItem.slug}}"
                       class="news__item-link">

          </router-link>
        </div>
      </div>

    </div>


  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "news-item",

  data: function () {
    return {
      promo: true,
      news: false,
      newsItems: [],
      promosItems: [],
      currentPage: 1,
      lastPage: 0,

    }
  },
  methods: {
    changeType() {
      if (this.promo) {
        this.promo = false
        this.news = true
        this.uploadNews()

      } else {
        this.news = false
        this.promo = true
        this.uploadNews()
      }
    },


    async uploadNews() {
      const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getArticleList?page=${this.currentPage}`, {
        headers: {'type': this.news === true ? 'news' : 'promo'}
      });
      this.lastPage = DATA_FROM_API.data.data.lastPage
      this.newsItems = DATA_FROM_API.data.data.list;
    },

    async addNews() {
      this.currentPage += 1;
      let DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getArticleList?page=${this.currentPage}`, {
        headers: {'type': this.news === true ? 'news' : 'promo'}
      });
      let data = DATA_FROM_API.data.data.list;
      for (let i = 0; i < data.length; i++) {
        this.newsItems.push(data[i]);
      }
    }
  },
  async created() {
    const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getArticleList?page=${this.currentPage}`, {
      headers: {'type': this.news === true ? 'news' : 'promo'}
    });
    this.lastPage = DATA_FROM_API.data.data.lastPage
    this.newsItems = DATA_FROM_API.data.data.list;
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.news {
  padding: 100px 0;

  &__block {
    display: flex;
    gap: 30px 2%;
    margin-top: 60px;
    flex-wrap: wrap;

    .news__item {
      position: relative;
      width: 32%;
      background: $colorWhite;
      padding: 20px;
      border-radius: 25px;
      @extend .transition;

      &-link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      }

      &:active {
        @extend .scaleMin
      }

      &-poster {
        img {
          width: 100%;
          border-radius: 12.5px;
        }

        border: 1px solid $bodyColor;
        border-radius: 12.5px;
        margin-bottom: 15px;
      }

      &-title {
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 1.1rem;
          line-height: 120%;
          letter-spacing: -0.025em;
          color: $colorBlack;
        }

        p {
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 150%;
          letter-spacing: -0.025em;
          color: $colorBlack;
        }
      }

    }

  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 60px;


  }


  @media screen and (max-width: 1000px) {

    .news__item {
      width: 49% !important;
    }

  }
  @media screen and (max-width: 600px) {

    .news__item {
      width: 100% !important;
    }

  }


}

</style>