<template>
  <div id="app">
<!--    <cursor-fx-->
<!--        color="#5463ff"-->
<!--        color-hover="#fff"-->


<!--    ></cursor-fx>-->
    <transition name="slide-fade" mode="out-in" >
      <router-view></router-view>
    </transition>


  </div>
</template>

<style lang="scss" >
@import "scss/globalStyles";
</style>
<script>
export default {
  data: function () {
    return {
      config: {
        lerps: {
          dot: 1,
          circle: 0.18,
          custom: 0.23,
        },
        scale: {
          ratio: 0.18,
          min: 0.5,
          max: 1,
        },
        opacity: 0.1,
      }
    }
  },
  created() {
    if(this.$route.fullPath=== "/ref=mf") {
      window.location.replace("https://admin.optikom.uz/api/v1/redirect");
    }
  }
}



</script>
