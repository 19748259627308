<template>
  <main>
    <headerMain/>
    <bigSlider
        data-cursor-hover
    />
    <tariffs/>
    <services/>
    <resources/>
    <support/>
    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import bigSlider from "@/components/bigSlider";
import services from "@/components/services";
import tariffs from "@/components/newTarifBlock";
import resources from "@/components/resources";
import support from "@/components/support";
import footerMain from "@/components/rp-blocks/footer";



export default {
  name: 'Home',
  components: {
    headerMain, bigSlider, services, tariffs, resources, support, footerMain,

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OPTIKOM',

  }



}
</script>
