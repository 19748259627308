import Vue from 'vue'
import VueRouter from 'vue-router'
import mainPage from '../views/mainPage.vue'
import internet from "@/views/internet";
import tv from "@/views/tv";
import news from "@/views/news";
import newsDetail from "@/views/newsDetail";
import about from "@/views/about";
import contacts from "@/views/contacts";
import vacancy from "@/views/vacancy";
import vacancyDetail from "@/views/vacancyDetail";
import corporate from "@/views/corporate";
import coverage from "@/views/coverage";
import services from "@/views/services";
import home from "@/views/home";
import notFound from "@/views/page404"
import optiMan from "@/views/optiMan";
import cabinetOptiman from "@/views/cabinetOptiman";
import documents from "@/components/documents";








Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: mainPage
    },
    {
        path: '/internet',
        name: 'internet',
        component: internet
    },
    {
        path: '/tv',
        name: 'tv',
        component: tv
    },
    {
        path: '/article',
        name: 'news',
        component: news,

    },
    {
        path: '/about',
        name: 'about',
        component: about,

    },
    {
        path: '/contact',
        name: 'contact',
        component: contacts,

    },
    {
        path: '/vacancy',
        name: 'vacancyr',
        component: vacancy,

    },
    {
        path: '/corporate',
        name: 'corporates',
        component: corporate,

    },
    {
        path: '/coverage',
        name: 'coverages',
        component: coverage,

    },
    {
        path: '/services',
        name: 'service',
        component: services,

    },
    {
        path: '/home',
        name: 'homePage',
        component: home,

    },
    {
        path: '/documents',
        name: 'documents',
        component: documents,

    },
    {path: '/article/show/:id-:slug', component: newsDetail, name: 'newsDetail'},
    {path: '/vacancyDetail/:id', component: vacancyDetail, name: 'vacancyDetail', props: true},
    {path: '/optiman', component: optiMan, name: 'cheackPage'},
    {path: '/optimanCabinet', component: cabinetOptiman, name: 'checkCabinet'},
    { path: "/:pathMatch(.*)*", name: "NotFound", component: notFound },



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,

})

export default router
