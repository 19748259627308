<template>
  <section>
    <div class="container-fluid">
      <article>
        <div class="article__poster">
          <img :alt=name :src="poster">
        </div>
        <h1>
          {{ name }}
        </h1>
        <p>
          {{ text }}
        </p>
      </article>
    </div>
  </section>
</template>

<script>
import axios from "axios";


export default {
  name: "news-det",
  data: function () {
    return {
      name: "",
      text: "",
      poster: "https://admin.optikom.uz/Articles/a0f9010b3f0ac2d8fef71b908c816b8c.jpg",
    }
  },



  async created() {
    let routeId=this.$route.params.id
    const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getArticleDetail/id=${routeId}`, {
      headers: {'lang': 'ru'}
    });
    let news=DATA_FROM_API.data.data
    this.name= news.name
    this.text= news.text
    this.poster= news.poster




  },



}
</script>


<style lang="scss" scoped>
@import "src/scss/vars";

section {
  padding: 100px;

  article {
    max-width: 1000px;
    margin: 0 auto;
    background: $colorWhite;
    padding: 50px;
    border-radius: 50px;
    display: block;
    .article__poster {
      display: flex;
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 500px;
      margin: 0 auto 30px auto;
      border-radius: 25px;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 128%;
    letter-spacing: -0.025em;
    color: $colorBlack;

  }

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 180%;
    letter-spacing: -0.025em;
    color: $colorBlack;
  }

  @media screen and (max-width: 1000px) {

    article {
      padding: 50px;

      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1.1rem;
      }
    }

  }

  @media screen and (max-width: 500px) {

    article {
      padding: 25px;
      border-radius: 25px;

      img {
        border-radius: 12.5px;
      }

      h1 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.8rem;
      }
    }

  }
}

</style>