<template>
  <section class="vacancy">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('vacancy.title') }}
        </h2>

      </div>

      <div class="vacancy__block">
        <div v-for="(vacancyItem, index) in vacancies" :key="index" class="vacancy__item">
          <div class="vacancy__item-title">
            <h3>
              {{ vacancyItem.name }}
            </h3>
          </div>
          <div class="vacancy__item-btn">
            <router-link :to="{name: 'vacancyDetail', params: {id:vacancyItem.id }}" class="btn body-c">
              {{ $t('utils.more') }}
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "vacancy__body",
  data: function () {
    return {
      vacancies: []

    }
  },
  async created() {

    const vacancy = await axios.get('https://admin.optikom.uz/api/v1/web/getVacancyList', {
      headers: {
        lang: 'ru',
      }
    }, {});
    this.vacancies = vacancy.data.data.list


  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.vacancy {
  padding: 100px 0;

  &__block {
    display: flex;
    gap: 30px 2%;
    flex-wrap: wrap;

    .vacancy__item {
      width: 23.5%;
      padding: 30px;
      background: $colorWhite;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-title {
        h3 {
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 132%;
          margin-bottom: 20px;
          /* or 37px */

          letter-spacing: -0.025em;
        }
      }

      &-btn {
        display: flex;
        width: 100%;
        justify-content: center;

        a {
          width: 100%;
          justify-content: center;
        }
      }
    }


  }

  .section__title {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1300px) {
    .vacancy__item {
      width: 32%;
    }

  }
  @media screen and (max-width: 1000px) {
    .vacancy__item {
      width: 49%;
    }

  }
  @media screen and (max-width: 650px) {
    .vacancy__item {
      width: 100%;
    }

  }

}


</style>