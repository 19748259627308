<template>
  <main>
    <headerMain/>
    <coverageBody/>

    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";
import coverageBody from "@/components/coverage-body";


export default {
  name: 'coverage',
  components: {
    footerMain, headerMain, coverageBody

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OPTIKOM',
    // all titles will be injected into this template
    titleTemplate: '%s | Зоны покрытия'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
