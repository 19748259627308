<template>
  <section class="coverage">
    <vs-dialog v-model="active" not-close overflow-hidden>
      <template #header>
        <h3>
          {{ $t('utils.input.createApplication') }}
        </h3>
      </template>
      <div class="con-content">
        <div class="form">
          <form @submit="form">
            <input v-model="name" mask="\+\1 (111) 111-1111" :placeholder="$t('utils.input.name')" required type="text">
            <input v-model="phone" v-maska="'+998(##)-###-##-##'" :placeholder="$t('utils.input.phone')" required
                   type="text">
            <input :value="district" hidden name="district">
            <input :value="street" hidden name="street">
            <input v-model="house" :placeholder="$t('utils.input.flat')" required type="text">
            <input v-model="flat" :placeholder="$t('utils.input.apartment')" required type="text">

            <button class="btn" data-cursor-hover>
              {{ $t('utils.connect') }}
            </button>
          </form>
        </div>
      </div>
    </vs-dialog>
    <vs-dialog v-model="active2" not-center width="550px">
      <template #header>
        <h4 style="margin-top: 20px">
          {{ $t('utils.thanks') }}
        </h4>
      </template>


      <div class="con-content">
        <p>
          {{ $t('utils.wait') }}
        </p>
      </div>

      <template #footer>
        <div class="con-footer" style="display: flex;justify-content: center">
          <vs-button class="btn body-c" transparent @click="active2=false, active=false">
            {{ $t('utils.close') }}
          </vs-button>
        </div>
      </template>
    </vs-dialog>
    <div class="container-fluid">
      <div class="section__title hasMinText center">
        <h2>
          {{ $t('coverage.address') }}
        </h2>
        <p>
          {{ $t('coverage.toCheckAddress') }}
        </p>

      </div>

      <div class="coverage__selection selects">

        <select @change="cityAction($event)">
          <option value="">
            {{ $t('utils.input.city') }}
          </option>
          <option value="1">
            Ташкент
          </option>
        </select>

        <select :disabled="districtShow" @change="districtAction($event)">
          <option value="0">
            {{ $t('utils.input.region') }}
          </option>
          <option v-for="(districtItem, index) in districts[0]" :key="index" :value="districtItem.id">
            {{ districtItem.name }}
          </option>
        </select>

        <select :disabled="streetShow" @change="streetAction($event)">
          <option value="0">
            {{ $t('utils.input.street') }}
          </option>
          <option v-for="(streetItem, index) in streets[0]" :key="index" :value="streetItem.id">
            {{ streetItem.name }}
          </option>
        </select>

      </div>
    </div>
  </section>

</template>

<script>
import axios from "axios";
import {maska} from "maska";


export default {
  name: "coverage-body",
  data: function () {
    return {
      district: '',
      street: '',
      districtShow: true,
      streetShow: true,
      districts: [],
      streets: [],
      active: false,
      active2: false,
      name: '',
      phone: '',
      message: '',
      house: '',
      flat: ''

    }
  },
  directives: {maska},
  methods: {
    async cityAction(event) {
      const districts = await axios.get(`https://admin.optikom.uz/api/v1/web/addresses/getDistricts?city_id=${event.target.value}`);

      this.districtShow = false;
      this.districts = districts.data.data;

    },
    async districtAction(event) {
      this.district = event.target.value
      const streets = await axios.get(`https://admin.optikom.uz/api/v1/web/addresses/getStreets?district_id=${event.target.value}`);
      this.streetShow = false;
      this.streets = streets.data.data;


    },
    knowToName() {
      for (let i = 0; i < this.districts[0].length; i++) {
        if (this.districts[0][i].id == this.district) {
          this.district = this.districts[0][i].name
        }
      }
      for (let i = 0; i < this.streets[0].length; i++) {
        if (this.streets[0][i].id == this.street) {
          this.street = this.streets[0][i].name
        }
      }
    },
    async streetAction(event) {
      this.street = event.target.value

      this.knowToName()
      this.active = true;
    },


    form(e) {
      axios.post('https://admin.optikom.uz/api/v1/web/postTicket', {
        name: this.name,
        phone: this.phone,
        tariff_id: '',
        district: this.district,
        street: this.street,
        house: this.house,
        flat: this.flat
      }, {
        headers: {
          lang: 'ru'
        }
      })
      this.active1 = false;
      this.active2 = true;

      e.preventDefault();


    },
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";

.coverage {
  padding: 100px 0;

  &__selection {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
    @media screen and (max-width: 800px) {
      flex-direction: column;

    }

    select {

      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: #fff !important;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 50%;
      padding: 10px 35px 10px 25px;
      border-radius: 10px;
      border: 1px solid $colorGray;

    }
  }
}

</style>