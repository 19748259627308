<template>
  <section class="why">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('internet.title') }}
        </h2>
      </div>

      <div class="why__block">

        <div class="why__block-item best">
          <div v-scroll-reveal="{ delay: 250 }" class="best__item">
            <div class="best__item-icon">
              <i class="icon-signal"></i>
            </div>

            <div class="best__item-title">
              <h4>
                {{ $t('internet.additions.first.title') }}
              </h4>
              <p>
                {{ $t('internet.additions.first.body') }}
              </p>
            </div>
          </div>
          <div v-scroll-reveal="{ delay: 300 }" class="best__item">
            <div class="best__item-icon">
              <i class="icon-speedtest"></i>
            </div>

            <div class="best__item-title">
              <h4>
                {{ $t('internet.additions.second.title') }}
              </h4>
              <p>
                {{ $t('internet.additions.second.body') }}
              </p>
            </div>
          </div>

        </div>
        <div v-scroll-reveal="{ delay: 350 }" class="why__block-item center">
          <img :src=router alt="">
        </div>
        <div class="why__block-item best">
          <div v-scroll-reveal="{ delay: 400 }" class="best__item">
            <div class="best__item-icon">
              <i class="icon-rocket"></i>
            </div>

            <div class="best__item-title">
              <h4>
                {{ $t('internet.additions.third.title') }}
              </h4>
              <p>
                {{ $t('internet.additions.third.body') }}
              </p>
            </div>
          </div>
          <div v-scroll-reveal="{ delay:  450 }" class="best__item">
            <div class="best__item-icon">
              <i class="icon-card"></i>
            </div>

            <div class="best__item-title">
              <h4>
                {{ $t('internet.additions.fourth.title') }}
              </h4>
              <p>
                {{ $t('internet.additions.fourth.body') }}
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "internet-why",
  data: function () {
    return {
      router: 'img/router.png'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.why {
  padding: 100px 0;

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    &-item {
      width: 30%;
      margin-right: 3%;

      img {
        width: 100%;
      }


    }

    .best {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      &__item {
        &-icon {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $colorLightBlue;
          border-radius: 25px;
          margin-bottom: 15px;

          i {
            font-size: 4.2rem;
            color: $colorBlue;
          }


        }

        &-title {
          max-width: 350px;
          margin: 0 auto;

          h4 {
            font-weight: 700;
            font-size: 1.3rem;
            line-height: 120%;
            text-align: center;
            letter-spacing: -0.025em;
            color: $colorBlack;
          }

          p {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 144%;
            text-align: center;
            color: $colorBlack;


          }
        }

      }

    }
  }

  @media screen and (max-width: 1000px) {
    .why__block {
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .why__block-item {
      width: 100%;
      margin-right: 0;


    }
    .best {
      flex-direction: row;

      &__item {
        width: 50%;
        margin-bottom: 30px;

        &-icon {
          width: 80px;
          height: 80px;

          i {
            font-size: 2rem;
          }
        }

        &-title {
          max-width: 250px;
        }
      }
    }
    .why__block-item.center {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .why__block {

      justify-content: center;
    }
    .why__block-item {
      justify-content: center !important;
      flex-direction: column !important;

      &:last-child {
        .best {
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }


    }
    .best {
      flex-direction: row;

      &__item {
        width: 100%;
        margin-bottom: 30px;

        &-icon {
          width: 80px;
          height: 80px;

          i {
            font-size: 2rem;
          }
        }

        &-title {
          max-width: 250px;
        }
      }
    }
    .why__block-item.center {
      display: none;
    }
  }
}


</style>