<template>
  <main>
    <headerMain/>
    <bigSlider
        data-cursor-hover
    />
    <internetWhy v-show="false"/>
    <services/>
    <support/>


    <footerMain/>


  </main>


</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain"
import bigSlider from "@/components/bigSlider";
import footerMain from "@/components/rp-blocks/footer";
import internetWhy from "@/components/internet-why";
import support from "@/components/support";
import services from "@/components/services";


export default {
  name: 'corporate',
  data: function () {
    return {
      news: [
        {
          title: 'Честный корпоративный интернет',
          text: 'Широкий спектр IT решений для Вашего бизнеса',
          photo: 'img/post2.png',
          btnText: 'Подробнее',
          btnRoute: 'internet',
        }
      ]
    }
  },
  components: {
    headerMain, bigSlider, footerMain, internetWhy, support, services

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OPTIKOM',
    // all titles will be injected into this template
    titleTemplate: '%s | Для офиса'
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";


</style>
