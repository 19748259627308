<template>
 <section>
   <headerMain/>
   <section>
     <div class="container">
       <div class="row">
         <div class="col-md-12 col-lg-12" >

           <h1 class="my-5">
             Публичная оферта
           </h1>
           OOO «BROSS-TELEKOM», в лице директора РАХМАНБЕРДИЕВА РУСТАМА АГЗАМОВИЧА действующего на основании Устава и Лицензии AA № 0008231 от 28.04.2021 г. на предоставление услуг телекоммуникаций, именуемое в дальнейшем Провайдер, настоящим предоставляет физическим лицам (акцептирующим) настоящую оферту на нижеследующих условиях.
           <br/>
           <br/>
           1.ОБЩИЕ ПОЛОЖЕНИЯ
           <br/>
           <br/>
           1.1. Провайдер, заключая настоящий Договор, руководствуется презумпцией наличия у Абонента необходимых навыков для работы на персональном компьютере, а также навыков работы в сети Интернет.
           <br/>
           <br/>
           1.2.В настоящей Публичной оферте используются следующие понятия:
           <br/>
           <br/>
           "Абонент" – физическое лицо, пользующееся услугами исключительно в личных целях без права коммерческого использования, с которым заключён Договор об оказании таких услуг, с выделением для этих целей уникальных аутентификационных данных;
           <br/>
           <br/>
           "Абонентское оборудование, оконечное оборудование" – технические средства для передачи и/или приёма сигналов по линиям связи (компьютер, сетевой кабель и пр.), расположенные по адресу Абонента;
           <br/>
           <br/>
           "Авторизация" – процесс анализа на сервере Провайдера введенных Абонентом Аутентификационных данных, по результатам которого определяется наличие у Абонента права получить услугу или войти в Личный кабинет.
           <br/>
           <br/>
           "Аутентификационные данные" – уникальные имя пользователя (login) и пароль (password) Абонента, используемые для доступа к Личному кабинету из сети Интернет и/или для получения соответствующей услуги.
           <br/>
           <br/>
           "Баланс лицевого счета" - разность между двумя суммами денежных средств в определенный момент времени. Первая сумма состоит из денежных средств, внесенных на Лицевой счет до данного момента времени; вторая сумма состоит из денежных средств, списанных с Лицевого счета до данного момента времени.
           <br/>
           <br/>
           "Внесение денежных средств на Лицевой счет" – авансовый платеж Абонента на расчетный счет Провайдера с указанием Лицевого счета. Платеж может быть осуществлен либо внесением наличных денежных средств в кассу Провайдера, либо с помощью электронных платёжных систем (Payme, Click, Apelsin, Paynet ).
           <br/>
           <br/>
           "Зона ответственности "Провайдера" - технические средства и каналы телекоммуникаций, находящиеся во владении или под управлением Провайдера.
           <br/>
           <br/>
           "Интернет" - глобальное добровольное, независимое объединение компьютерных сетей и информационных ресурсов, принадлежащих множеству различных людей и организаций.
           <br/>
           <br/>
           "Лицевой счет" – счет, на котором фиксируются авансовые платежи Абонента и суммы денежных средств, удержанные (списанные) из данных платежей в качестве оплаты за услуги. Лицевой счет уникален, расположен в базе данных Провайдера, имеет постоянный характер в течение всего периода оказания услуг, отражает учёт сведений о поступлении средств и их расходовании. Страница Лицевого счёта является функциональной опцией Личного кабинета и располагается на Официальном сайте услуги в разделе Личный кабинет.
           <br/>
           <br/>
           "Личный кабинет" – web-страница на Официальном сайте услуги Провайдера, содержащая статистическую информацию об объеме полученных Услуг и текущем состоянии Лицевого счета. При этом информация, отображаемая в Личном кабинете, является справочной и может отличаться от информации в финансовых системах Провайдера. Кроме того, на данной странице может осуществляться подписка Абонента на конкретные услуги, отказ от них.
           <br/>
           <br/>
           "Отчетный месяц" – 30 дней предоставления конкретной услуги, которому поставлены в соответствие определенные платежи Абонента, согласно условиям выбранного Абонентом тарифного плана, соответствующего данной услуге.
           <br/>
           <br/>
           "Официальный сайт услуги" – информационный ресурс телекоммуникационной сети Провайдера, расположенный по адресу: http://www.optikom.uz
           <br/>
           <br/>
           "Провайдер" – OOO «BROSS-TELEKOM», оказывающее услуги телекоммуникационных сетей (сети передачи данных и сети телекоммуникаций) на основании соответствующих лицензий;
           <br/>
           <br/>
           «Публичная оферта» - настоящий документ, который заключается между Абонентом и Провайдером, посредством подписания Абонентом «Согласия с условиями публичной оферты OOO «BROSS-TELEKOM», опубликованного в сети на официальном сайте Провайдера, расположенного по адресу http://www.optikom.uz , далее по тексту «Договор»;
           <br/>
           <br/>
           "Трафик абонента" – совокупность любых наборов данных, полученных из сети Интернет оборудованием абонента (входящий трафик) и отправленных в сеть Интернет оборудованием абонента (исходящий трафик) через сеть провайдера;
           <br/>
           <br/>
           "Услуга"–подключение и предоставление услуг сетей передачи данных, в том числе Интернет, а также действия, которые необходимо предпринять для получения данных услуг.
           <br/>
           <br/>
           «Акцепт оферты» - полное и безоговорочное принятие оферты путем подтверждения личных данных  и принятия условий настоящего договора в личном кабинете на официальном сайте http://www.optikom.uz Абонентом «Согласие с условиями публичной оферты OOO «BROSS-TELEKOM» далее «Согласие Абонента» (Приложение №1).
           <br/>
           <br/>
           2. ПРЕДМЕТ ДОГОВОРА.
           <br/>
           <br/>
           2.1. Провайдер оказывает Абоненту услуги сетей передачи данных, в том числе доступа к сети Интернет, а Абонент пользуется услугами в соответствии с условиями настоящего Договора и обязуется оплачивать оказанные услуги в срок и в полном объеме, по тарифам, указанным на официальном сайте Услуги.
           <br/>
           <br/>
           2.2. В рамках Договора при необходимости Провайдер вправе предоставлять Абоненту, по его запросу, дополнительные услуги, перечисленные и тарифицируемые, согласно тарифам, опубликованным на официальном сайте Услуги.
           <br/>
           <br/>
           2.3. На период оказания услуг по настоящему Договору Провайдер при отсутствии собственного необходимого оборудования у Абонента, передает Абоненту для подключения к Услуге телекоммуникационное оборудование в исправном состоянии. В случае расторжения настоящего Договора, Абонент, получивший оборудование от Провайдера, обязан с момента расторжения Договора вернуть Провайдеру полученное оборудование в том состоянии, в каком его принял, с учетом естественного износа.
           <br/>
           <br/>
           2.4. Передача Абоненту оборудования для подключения к услуге, его возврат Провайдеру или замена производятся согласно Приложению № 1 к Договору.
           <br/>
           <br/>
           2.5. В случае наличия у Абонента собственного телекоммуникационного оборудования, пригодного для полноценного пользования Услугами " Провайдера" по технологиям xPON и FTTx пункты 2.3. и 2.4. теряют свою силу.
           <br/>
           <br/>
           2.6. Факт активации  «Согласия Абонента» в личном кабинете (Приложение №1) является полным и безоговорочным принятием Абонентом условий Договора и всех ее Приложений, составляющих неотъемлемую часть Договора (статья 370 ГК РУз).
           <br/>
           <br/>
           3. УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ.
           <br/>
           <br/>
           3.1. Срок подключения Абонента к Услуге составляет не более 10 (десяти) рабочих дней с момента оплаты подключения и абонентской платы за первый месяц в соответствии с условиями п.п. 4.3 и 4.4.
           <br/>
           <br/>
           3.2. Абонент должен произвести авторизацию в сети не позднее 5 (Пяти) рабочих дней с момента завершения технических работ по подключению Услуги. Авторизация Абонента осуществляется посредством ввода при подключении к сети персональных Аутификационных данных Абонента.
           <br/>
           <br/>
           3.3. Моментом подключения Абонента к услуге (началом предоставления услуги) является момент первой успешной Авторизации Абонента.
           <br/>
           <br/>
           3.4. Фактическая дата начала пользования Абонентом Услугами определяется Провайдером на основании данных программного контроля соединений или аппаратуры учета стоимости (биллинга), установленной на сети Провайдера. Факт предоставления Абоненту Услуг подтверждается данными аппаратуры учета стоимости (биллинга) Провайдера.
           <br/>
           <br/>
           3.5. Идентификация Абонента и доступ к Услугам и Личному кабинету осуществляется с использованием персональных Аутификационных данных Абонента. Посредством одного логина одновременно может быть установлено только одно соединение.
           <br/>
           <br/>
           3.6. Персональные Аутификационные данные Абонента присваивается Абоненту в офисе Провайдера либо на месте заключения договора, Login присвоенный Абоненту не изменяется. Абонент обязуется сменить начальный пароль (password) в течение 24 часов с момента первой успешной авторизации.
           <br/>
           <br/>
           4. СТОИМОСТЬ УСЛУГ И ПОРЯДОК ОПЛАТЫ
           <br/>
           <br/>
           4.1. Услуги предоставляются на условиях предоплаты (далее – «Предоплата»). С момента начала пользования Услугами Провайдер ведет учет средств на лицевом счете Абонента. Средства на лицевом счете могут быть использованы Абонентом исключительно для оплаты Услуг Провайдера.
           <br/>
           <br/>
           4.2. Стоимость Услуг и правила тарификации определяются в соответствии с тарифами, установленными Провайдером (далее – «Тарифы»), являющимися неотъемлемой частью настоящего Договора. Тарифы размещаются на Официальном сайте услуги.
           <br/>
           <br/>
           4.3. Первоначальный платеж включает в себя плату за подключение к Услуге и ежемесячную Абонентскую плату за пользование выбранными услугами согласно действующим тарифам и оплачивается в полном объеме, путем внесения денежных средств на лицевой счет Абонента.
           <br/>
           <br/>
           4.4. Абонентская плата за первый месяц рассчитывается, из расчёта 30 календарных дней.
           <br/>
           <br/>
           4.5. Абонент может заказать и оплатить со своего Лицевого Счета дополнительные услуги Провайдера. Информация о составе, стоимости и порядке предоставления дополнительных услуг публикуется Провайдером на официальном сайте. Фактом начала использования Абонентом дополнительной услуги является согласие Абонента с условиями ее предоставления и тарифами на нее и оплата услуги.
           <br/>
           <br/>
           4.6. Списание Провайдером средств с лицевого счета Абонента производится:
           <br/>
           <br/>
           4.6.1. Списание абонентской платы за текущий отчетный период производится в форме 100% предоплаты.
           <br/>
           <br/>
           4.6.2. Списание средств за использование трафика, выходящего за границы лимита тарифа (сверхлимитный трафик) производится по факту использования сверхлимитного трафика.
           <br/>
           <br/>
           4.6.3. Списание средств за дополнительные услуги (интернет пакеты и др.) производится едино разово при заказе услуги в форме 100% предоплаты
           <br/>
           <br/>
           4.7.Услуги предоставляются Абоненту только при положительном балансе Лицевого счета.Предоставление Услуги автоматически останавливается, если баланс Абонента становится нулевым или недостаточным для снятия абонентской платы. Предоставление права пользования Услугой возобновляется после пополнения баланса Лицевого счета до размера, достаточного для возобновления действия услуги.
           <br/>
           <br/>
           4.8. Если Абонент в течение 60 календарных дней,  следующих, после приостановления услуги не пополнит баланс лицевого счета до размера, достаточного для возобновления действия услуги, Провайдер вправе в одностороннем порядке расторгнуть Договор и отключить Абонента от сети порта FTTx либо xPON.
           <br/>
           <br/>
           4.9. Об изменении действующих и введении новых тарифов Провайдер извещает Абонентов путем размещения информации на Официальном сайте услуги не менее чем за 10 дней до вступления изменений в силу.
           <br/>
           <br/>
           4.10. Тарифы на услуги указаны в национальной валюте (СУМ) и включают в себя все налоги и сборы, действующие на момент оплаты. Платежи осуществляются в национальной валюте (СУМ) Республики Узбекистан.
           <br/>
           <br/>
           4.11. Провайдер вправе в одностороннем порядке изменять Тарифы в соответствии с п. 5.2.4 настоящего Договора.
           <br/>
           <br/>
           4.12. В случае превышения лимита трафика, установленного тарифным планом, Абонент оплачивает за каждый 1 Мбайт трафика сверх лимита по тарифам указанного плана.
           <br/>
           <br/>
           4.13. Провайдер имеет право осуществлять тарификацию сверхлимитного трафика по объему преобладающего в расчетном периоде направлению (входящему или исходящему) трафика абонента. Если у абонента в отчетном периоде преобладает исходящий трафик, провайдер имеет право осуществлять в этом периоде тарификацию по исходящему трафику.
           <br/>
           <br/>
           4.14. Доступ Абонента к услуге, после выработки лимита трафика, установленного выбранным Абонентом тарифным планом, предоставляется при наличии на его Лицевом счете положительного баланса.
           <br/>
           <br/>
           4.15. Положительный остаток между внесенным Абонентом платежом за сверхлимитный трафик и фактической суммой его потребления на последний день месяца, засчитывается в счет оплаты ежемесячной Абонентской платы за следующий месяц.
           <br/>
           <br/>
           4.16. Не израсходованный Абонентом в отчетном месяце объем трафика, включенный в ежемесячную Абонентскую плату, на следующий месяц не переносится, если данная возможность не предусмотрена условием тарифного плана.
           <br/>
           <br/>
           4.17. Абонент может повторно заключить Договор, в случае расторжения Договора в соответствии с п. 4.8, при условии оплаты стоимости повторного подключения согласно действующим тарифам, путем внесения необходимой денежной суммы в кассу Провайдера или электронными системами приема платежей.
           <br/>
           <br/>
           4.18. В случае расторжения Договора по заявлению Абонента, поданного Провайдеру в порядке, предусмотренном п. 11.3. настоящего Договора, Абоненту возвращаются не списанные денежные средства, остающиеся на лицевом счете на дату расторжения в порядке, установленном п. 11.4. Договора, за исключением ежемесячной Абонентской платы за месяц, в котором Абонент подает заявление о расторжении Договора.
           <br/>
           <br/>
           4.19. Если по каким - либо причинам Абонент хочет сделать перерыв в пользовании услугой, при котором на время перерыва не будет осуществляться начисление ежемесячной Абонентской платы, он должен выбрать в личном кабинете дополнительную услугу резервирования (блокирования возможности пользования  Услугой, и закрепления на срок резервирования за Абонентом его реквизитов в системе учета и порта оборудования Провайдера).
           <br/>
           <br/>
           4.19.1. Срок резервирования может составлять до 90 календарных дней подряд.
           <br/>
           <br/>
           4.19.2. Стоимость дополнительной услуги резервирования указана в действующих тарифах, оплата осуществляется путем внесения необходимой денежной суммы на лицевой счет Абонента.
           <br/>
           <br/>
           4.20. Смена тарифного плана возможна путем подачи письменного заявления в офисе Провайдера или подачи заявки в Личном кабинете до окончания текущего отчетного периода. Смена тарифного плана Абонента производится после окончания текущего отчетного периода.
           <br/>
           <br/>
           4.21. Если, по каким-либо причинам, Абонент хочет перенести предоставление услуг в другое место расположения (по иному адресу), то он должен подать письменное заявление в офис Провайдера на перенос услуги с предоставлением необходимого пакета документов.
           <br/>
           <br/>
           4.21.1. Услуга «переноса услуги» предоставляется при наличии установленного оборудования Провайдера по данному адресу и оформляется путем подписания Сторонами дополнительного соглашения к «Согласию Абонента», ранее подписанному Абонентом.
           <br/>
           <br/>
           4.21.2. Стоимость дополнительной услуги переноса указана в действующих тарифах. Оплата осуществляется путем внесения необходимой денежной суммы на Лицевой счет Абонента.
           <br/>
           <br/>
           4.21.3. Дополнительная услуга переноса предоставляется в течение 10 (Десяти) рабочих дней с момента подписания Сторонами дополнительного соглашения, предоставив при этом необходимый пакет документов по новому адресу подключения.
           <br/>
           <br/>
           4.22. Все суммы, предоставляемые Абоненту в ходе рекламных акций, добавленные в виде бонусов от Провайдера могут быть израсходованы исключительно на оплату Услуг и при расторжении Договора не выплачиваются.
           <br/>
           <br/>
           4.23. Если по истечении 5(пяти) календарных дней месяца, следующего за отчетным, Абонентом не было подано письменно обоснованной претензии, касающейся объема и качества услуг, предоставленных в отчетном месяце, Услуга считается оказанной надлежащим образом.
           <br/>
           <br/>
           <br/>
           <br/>
           5. ПРАВА И ОБЯЗАННОСТИ ПРОВАЙДЕРА
           <br/>
           <br/>
           5.1. Провайдер обязуется:
           <br/>
           <br/>
           5.1.1. Предоставлять Абоненту Услуги в соответствии с условиями, описанными в настоящем Договоре с учетом требований действующих нормативных актов Республики Узбекистан.
           <br/>
           <br/>
           5.1.2. Своевременно зачислять авансовые платежи Абонента на его Лицевой счет.
           <br/>
           <br/>
           5.1.3. Предоставлять Абоненту возможность получения телефонных консультаций службы технической поддержки по телефону +99878-777-00-01 (круглосуточно, 7 дней в неделю). Объем консультаций ограничивается конкретными вопросами, связанными с предоставлением Услуг.
           <br/>
           <br/>
           5.1.4. Предоставлять Абоненту возможность доступа к Личному кабинету. В случае приостановления предоставления Услуг, Личный кабинет остается доступным для Абонента через сеть Интернет в течение действия Договора.
           <br/>
           <br/>
           5.1.5. Предпринимать общепринятые технические и организационные меры для обеспечения конфиденциальности информации, касающейся порядка пользования Услугой Абонентом и прочих условий заключенного им Договора.
           <br/>
           <br/>
           5.1.6. Предоставлять Абоненту, по его заявке, дополнительные услуги в соответствии с условиями настоящего Договора;
           <br/>
           <br/>
           5.1.7. Уведомлять Абонента на Официальном сайте или на официальных страницах в социальных сетях услуги о сроках начала и продолжительности проведения, профилактических и плановых ремонтных работ, которые могут привести к нарушению нормального режима предоставления услуг.
           <br/>
           <br/>
           5.2. Провайдер имеет право:
           <br/>
           <br/>
           5.2.1. Отказать в предоставлении услуг до внесения Абонентом 100% предоплаты Первоначального платежа или ежемесячной Абонентской платы за услуги;
           <br/>
           <br/>
           5.2.2. В одностороннем порядке отказать Абоненту в выполнении работ по подключению и предоставлении услуг, если в необходимых документах, полученных от Абонента, содержится недостоверная или недостаточная информация;
           <br/>
           <br/>
           5.2.3. Отказать Абоненту в предоставлении услуги при условии надлежащего обоснования данного отказа в следующих случаях:
           <br/>
           <br/>
           - не соблюдения обязанностей указанных в «Правилах оказания услуг телекоммуникаций » утверждённый приказом Министра Министерства по развитию информационных технологий и коммуникаций Республики Узбекистан от 30.06.2020 года №208-МХ;
           <br/>
           <br/>
           - при невозможности предоставления Услуг, ввиду каких-либо технических, физических, топографических или иных естественных препятствий;
           <br/>
           <br/>
           5.2.4. В одностороннем порядке вводить новые тарифы и изменять действующие, предварительно уведомив об этом Абонента не менее чем за 10 (Десять) календарных дней до их изменения, путем размещения информации на Официальном сайте или на официальных страницах в социальных сетях услуги.
           <br/>
           <br/>
           5.2.5. Прекратить предоставление услуг Абоненту, с расторжением Договора в одностороннем порядке, в случаях:
           <br/>
           <br/>
           - нарушения Абонентом условий настоящего Договора и Приложений к ней;
           <br/>
           <br/>
           - любых действий Абонента, повлекших за собой сбой в работе сети Провайдера;
           <br/>
           <br/>
           - использования получаемых услуг в незаконных или коммерческих целях;
           <br/>
           <br/>
           - использования услуги в коммерческих целях путем организации локальных сетей и/или подключения к Услуге с соединением между собой двух или более компьютеров;
           <br/>
           <br/>
           - использования услуги при организации пунктов коллективного пользования ("Интернет-клубов", "Интернет-кафе", любых учебно-образовательных центров);
           <br/>
           <br/>
           - действий Абонента, направленных на получение несанкционированного доступа к ресурсам сетей;
           <br/>
           - при обнаружении использования услуги юридическими лицами, на условиях предоставления услуг для физических лиц.
           <br/>
           <br/>
           5.2.6. Отказать в передаче любой информации, распространение которой противоречит законодательству Республики Узбекистан.
           <br/>
           <br/>
           6. ПРАВА И ОБЯЗАННОСТИ АБОНЕНТА
           <br/>
           <br/>
           6.1. Абонент обязуется:
           <br/>
           <br/>
           6.1.1.Поддерживать положительный баланс Лицевого счета, своевременно производя необходимые авансовые платежи на расчетный счет Провайдера.
           <br/>
           <br/>
           6.1.2. Абонент принимает на себя всю ответственность за использование Услуг. Не допускается любое коммерческое использование Услуги по Договору с целью получения Абонентом выгоды (перепродажа Услуги любому иному физическому или юридическому лицу).
           <br/>
           <br/>
           6.1.3. Активировать Услугу в течение 5 (пяти) рабочих дней с момента завершения технических работ по подключению Услуги.
           <br/>
           <br/>
           6.1.4. Пользоваться Услугами с соблюдением действующего законодательства Республики Узбекистан, не нарушая прав и законных интересов третьих лиц и не причиняя им ущерба.
           <br/>
           <br/>
           6.1.5. Периодически (не реже одного раза в течение 7 дней) проверять наличие изменений на Официальном сайте услуги.
           <br/>
           <br/>
           6.2. Абонент имеет право:
           <br/>
           <br/>
           6.2.1. Пользоваться консультативной и технической поддержкой Провайдера при возникновении вопросов, связанных с использованием предоставляемых услуг.
           <br/>
           <br/>
           6.2.2. Получать информацию о потребительских свойствах и качестве оказываемых услуг на сайте Провайдера.
           <br/>
           <br/>
           6.2.3. Получать информацию в Личном кабинете об оказанных услугах и остатке средств на Лицевом счете.
           <br/>
           <br/>
           6.2.4. Предъявлять претензии в порядке, установленном Договором.
           <br/>
           <br/>
           7. ОТВЕТСТВЕННОСТЬ ПРОВАЙДЕРА.
           <br/>
           <br/>
           7.1. Провайдер несет ответственность за выполнение своих обязательств по Договору в соответствии с действующим законодательством.
           <br/>
           <br/>
           7.2. Провайдер не несет ответственность за перерывы в предоставлении Услуги в случае выхода из строя оборудования и/или сбоев программного обеспечения, не принадлежащего Провайдеру, не находящегося под управлением Провайдера, либо произошедшего по независящим от Провайдера причинам.
           <br/>
           <br/>
           7.3. Провайдер не несет ответственность за полные или частичные прерывания предоставления услуг, связанные с заменой оборудования, программного обеспечения или проведения других работ, вызванных необходимостью поддержания работоспособности и развития сети.
           <br/>
           <br/>
           7.4. Провайдер не несет ответственность за функционирование и доступность отдельных сегментов сети Интернет.
           <br/>
           <br/>
           7.5. Провайдер не гарантирует возможность информационного обмена с теми узлами или серверами в сети Интернет, которые расположены вне зоны ответственности Провайдера.
           <br/>
           <br/>
           7.6. Провайдер не несет ответственность за кратковременные (не более 15 минут) перерывы в предоставлении услуг.
           <br/>
           <br/>
           7.7. Провайдер не несет ответственности за любые действия третьих лиц, имевшие место при введении Аутентификационных данных Абонента, а также за их последствия.
           <br/>
           <br/>
           7.8. Провайдер не несет ответственность за упущенную выгоду, прямой или косвенный ущерб, понесенный Абонентом в результате использования или невозможности использования Услуги, в том числе понесенный в результате ошибок, пропусков, перерывов в работе, изменения функций, дефектов, задержек в работе и т.п. при передаче данных.
           <br/>
           <br/>
           7.9. Провайдер не несет ответственности за качество городских и иных сетей связи общего пользования, а также за ухудшение качества и/или перебои в предоставлении Услуг, происходящие прямо или косвенно по причинам, которые находятся вне зоны ответственности Провайдера.
           <br/>
           <br/>
           7.10. Провайдер не несет ответственность за достоверность и содержание информации, получаемой и передаваемой Абонентом при пользовании Услугами
           <br/>
           <br/>
           7.11. Провайдер не несет ответственность за ущерб, понесенный Абонентом в связи с разглашением Абонентом или утерей Абонентом учетной информации, и иной информации.
           <br/>
           <br/>
           7.12. При прекращении доступа к Услугам Провайдер не несет ответственности за извещение или не извещение любых третьих сторон о лишении Абонента доступа к Услугам и за возможные последствия, возникшие в результате такого предупреждения или его отсутствия.
           <br/>
           <br/>
           8. ОТВЕТСТВЕННОСТЬ АБОНЕНТА
           <br/>
           <br/>
           8.1. Абонент несет ответственность за выполнение своих обязательств в соответствии с действующим законодательством.
           <br/>
           <br/>
           8.2. Абонент несет ответственность за выполнение Правил пользования услугами приведенных в Приложениях к Договору.
           <br/>
           <br/>
           8.3. Абонент несет ответственность в соответствии с действующим законодательством за все действия, предпринятые посредством пользования Услуг, а также их последствия.
           <br/>
           <br/>
           8.4. Абонент несет полную ответственность за сохранение конфиденциальности и защиту своих Аутентификационных данных от третьих лиц, а также за последствия, которые могут возникнуть по причине разглашения и/или несанкционированного использования Аутификационных данных.
           <br/>
           <br/>
           8.5. Абонент принимает на себя полную ответственность и риски, связанные с использованием материалов, товаров и услуг, полученных при использовании. Услуги.
           <br/>
           <br/>
           8.6. Абонент самостоятельно отвечает за содержание, достоверность и правомерность информации, передаваемой Абонентом с использованием Услуг.
           <br/>
           <br/>
           9. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ
           <br/>
           <br/>
           9.1. Стороны освобождаются от ответственность за неисполнение или ненадлежащее исполнение обязательств, вытекающих из Договора, если причиной неисполнения (ненадлежащего исполнения) являются обстоятельства непреодолимой силы (стихийные бедствия, пожары, техногенные аварии и катастрофы, аварии на инженерных сооружениях и коммуникациях, нормативные акты органов государственной власти и местного самоуправления, лицензирующего органа препятствующие исполнению Сторонами своих обязательств по Договору, и так далее), то есть чрезвычайные и непреодолимые при данных условиях обстоятельства, наступившие после заключения Договора.
           <br/>
           <br/>
           9.2. Сторона, не исполнившая обязательства, не вправе ссылаться на обстоятельства, возникшие после наступления срока исполнения обязательства.
           <br/>
           <br/>
           9.3. Провайдер, пострадавший от действия обстоятельств непреодолимой силы, предусмотренных п.9.1 Договора, обязан в течение 5 (пяти) рабочих дней с момента возникновения и с момента прекращения обстоятельств непреодолимой силы, разместить информацию об их возникновении и, соответственно, о прекращении таких обстоятельств на Официальном сайте услуги либо на официальных страницах в социальных сетях.
           <br/>
           <br/>
           9.4. В случае не уведомления другой стороны о наступлении и прекращении обстоятельств непреодолимой силы, заинтересованная сторона не вправе ссылаться на них, как на основания для освобождения от ответственности, за исключением случая, когда наступление подобных обстоятельств препятствует также и уведомлению.
           <br/>
           <br/>
           9.5. При наступлении обстоятельств непреодолимой силы срок исполнения обязательств по настоящему Договору отодвигается соразмерно времени, в течение которого продолжают действовать такие обстоятельства, без возмещения каких-либо убытков.
           <br/>
           <br/>
           9.6. Если обстоятельства непреодолимой силы, от которых пострадал Провайдер, длятся более 30 (тридцати) дней подряд, то Провайдер вправе отказаться от исполнения обязательств по Договору на указанном основании путем размещения соответствующего объявления. При этом Договор считается расторгнутым с даты, указанной в объявлении Провайдера.
           <br/>
           <br/>
           10. УРЕГУЛИРОВАНИЕ СПОРОВ
           <br/>
           <br/>
           10.1. В случае возникновения любых споров или разногласий, связанных с исполнением Договора, Стороны приложат все усилия для их разрешения путем проведения переговоров между Сторонами.
           <br/>
           <br/>
           10.2.Если споры не будут разрешены путем переговоров, споры подлежат разрешению в уполномоченном суде в порядке, установленном действующим законодательством.
           <br/>
           <br/>
           10.3. Процедуре передачи спорного отношения на рассмотрение уполномоченного суда, должна предшествовать процедура досудебного урегулирования спорного отношения, путем заявления претензии.
           <br/>
           <br/>
           10.4. Срок предъявления Абонентом претензий по Услугам равен одному месяцу с момента оказания Услуг. Претензии, предъявленные по истечении этого срока, Провайдер вправе не рассматривать.
           <br/>
           <br/>
           10.4. Претензии передаются Сторонами друг другу следующим образом:
           <br/>
           <br/>
           10.4.1. От Провайдера Абоненту - в письменной форме посредством факсимильной связи или письма заказной почтой.
           <br/>
           <br/>
           10.4.2. От Абонента Провайдеру - в письменной форме посредством факсимильной связи или письма заказной почтой.
           <br/>
           <br/>
           10.5. Претензия должна содержать номер лицевого счёта, Ф.И.О. Абонента, а также его адрес для направления ответа. Без указания данных сведений Провайдер вправе отказаться от принятия претензии к рассмотрению.
           <br/>
           <br/>
           10.6. Результаты рассмотрения письменных претензий сообщаются заявителю письменно в течение одного месяца со дня получения оригинала претензии Абонента Провайдером.
           <br/>
           <br/>
           11. СРОК ДЕЙСТВИЯ ДОГОВОРА, ПОРЯДОК ИЗМЕНЕНИЯ ЕЕ УСЛОВИЙ ИЛИ ПРЕКРАЩЕНИЯ.
           <br/>
           <br/>
           11.1. Настоящий Договор является бессрочным, и все его условия действуют до момента отказа одной из сторон исполнять свои обязательства, в этом случае Договор считается расторгнутым с момента такого отказа.
           <br/>
           <br/>
           11.2. Отказ Абонента от дополнительной услуги, при продолжении пользования Услугой, производится путем подачи заявления Абонентом через личный кабинет. В случае получения Провайдером данного заявления, подписка Абонента на дополнительную услугу аннулируется. Провайдер прекращает предоставление этой дополнительной услуги и взимание оплаты за дополнительную услугу с даты, указанной в данном заявлении Абонента. В случае отсутствия указания этой даты в заявлении Абонента предоставление дополнительной услуги и взимание оплаты за дополнительную услугу прекращается со дня регистрации отказа Абонента, осуществляемой в течение 5-ти рабочих дней после получения данного заявления Абонента.
           <br/>
           <br/>
           11.3. Абонент имеет право в одностороннем порядке расторгнуть Договор путем подачи заявления о расторжении Договора в личном кабинете.
           <br/>
           <br/>
           11.4. Возврат денежных средств Абоненту производится Провайдером на основании письменного заявления абонента, путем зачисления на банковскую карту Абонента, денежных средств в национальной валюте. В заявлении на возврат, в обязательном порядке, должны быть указаны следующие реквизиты:
           <br/>
           <br/>
           -Наименование банка, в котором открыта пластиковая карта Абонента;
           <br/>
           <br/>
           -МФО Банка;
           <br/>
           <br/>
           -Транзитный счет банка, на который следует перечислить денежные средства;
           <br/>
           <br/>
           -Лицевой счет пластиковой карты Абонента.
           <br/>
           <br/>
           11.5. Провайдер вправе изменять условия договора и/или ее Приложений, в том числе корректировать действующие тарифы, вводить новые Приложения к Договору, информируя Абонента путем размещения объявления на Официальном сайте услуги не менее чем за 10 (Десять) календарных дней до вступления изменений в силу.
           <br/>
           <br/>
           11.7. Факт размещения объявления на Официальном сайте услуги, безусловно, является надлежащим выполнением Провайдером обязательства по информированию Абонента.
           <br/>
           <br/>
           11.8. Абонент ответственен за регулярную проверку наличия изменений на Официальном сайте услуги. Продолжение пользования услугами спустя 10 (Десять) календарных дней после уведомления об изменении текущих положений Договора и Приложений к ней рассматривается как безоговорочное присоединение к данным положениям и согласие с ними.
           <br/>
           <br/>
           12. ПРОЧИЕ УСЛОВИЯ
           <br/>
           <br/>
           12.1.Провайдеримеет право предоставить информацию об Абоненте соответствующим службам или организациям согласно требованиям Законодательства РУз
           <br/>
           <br/>
           <br/>
           12.2. В случае утери Абонентом Аутентификационных данных для доступа к какой-либо конкретной услуге повторное сообщение Абоненту утерянных данных и/или замена пароля осуществляется Провайдером по обращению Абонента в Абонентский отдел или Callcentre – служба технической поддержки, с указанием паспортных данных Абонента. Провайдер осуществляет повторное сообщение Абоненту утерянных данных и/или осуществляет замену пароля только при условии правильного ответа Абонента на контрольный вопрос.
           <br/>
           <br/>
           12.3. Во всем, что не предусмотрено настоящим Договором, стороны будут руководствоваться действующим гражданским законодательством Республики Узбекистан.
           <br/>
           <br/>
           12.4. При изменении Ф.И.О., паспортных данных, места жительства, контактных номеров телефонов Абонент обязуется извещать о таких изменениях Провайдера путем письменного уведомления в десятидневный срок с момента такого изменения. В противном случае сообщение Провайдера, переданное по известному последнему адресу или контактному номеру факсов Абонента, считается переданным надлежащим образом.
           <br/>
           <br/>
           <br/>
           OOO «BROSS-TELEKOM»
           <br/>
           <br/>
           Банковские реквизиты:
           <br/>
           <br/>
           р/с 2020 8000 0053 3674 3001
           <br/>
           KAPITAL BANK OPERU
           <br/>
           МФО 00974, ИНН 308 148 877,
           <br/>
           ОКОЭД  61900
           <br/>
           Юридический адрес : Узбекистан,
           <br/>
           г. Ташкент, Сергели 7 дом 33 кв 20
           <br/>
           e-mail: info@optikom.uz
           <br/>
           Телефон: (+99878) 777 00 01
           <br/>
           <br/>

         </div>

       </div>
     </div>
   </section>
   <footerMain/>
 </section>
</template>

<script>
import headerMain from "@/components/rp-blocks/headerMain";
import footerMain from "@/components/rp-blocks/footer";



export default {
  name: "vacancyDetail-body",
  components: {
    headerMain, footerMain
  },
  metaInfo: {
    title: 'OPTIKOM',
    titleTemplate: '%s | Публичная оферта'
  }

}
</script>

<style lang="scss" scoped>


</style>