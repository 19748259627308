<template>
  <section class="optimedia">

    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{$t('resourceBlock.title')}}
        </h2>
      </div>

      <div class="optimedia__logo">
        <a href="https://optimedia.uz" target="_blank">
          <img :src=logoUrl alt="optimedia">
        </a>
      </div>
      <div class="optimedia__text">
        <h2>
          10,000+
          <small>
            контента, который дополняется каждый день

          </small>
        </h2>
      </div>

      <div class="optimedia__btn">
        <a class="btn blue" href="https://optimedia.uz" target="_blank">
          {{$t('resourceBlock.freeTry')}}
        </a>
      </div>


      <div class="optimedia__film">
        <div class="swiper film-swipe">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div v-for="(filmItem, index) in dataFromApi" :key="index" class="swiper-slide">
              <filmItem

                  :film=filmItem

              />

            </div>

          </div>
        </div>


        <button class="freeSwiper-button-next">
          <svg id="Capa_1" style="enable-background:new 0 0 47.255 47.255;" viewBox="0 0 47.255 47.255" x="0px"
               xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
<g>
	<path d="M12.314,47.255c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l21.92-21.92l-21.92-21.92
		c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L35.648,22.92c0.391,0.391,0.391,1.023,0,1.414L13.021,46.962
		C12.825,47.157,12.57,47.255,12.314,47.255z"/>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>

        </button>
        <button class="freeSwiper-button-prev">
          <svg id="Capa_1" style="enable-background:new 0 0 47.255 47.255;" viewBox="0 0 47.255 47.255" x="0px"
               xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
<g>
	<path d="M12.314,47.255c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l21.92-21.92l-21.92-21.92
		c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L35.648,22.92c0.391,0.391,0.391,1.023,0,1.414L13.021,46.962
		C12.825,47.157,12.57,47.255,12.314,47.255z"/>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>
        </button>
      </div>
    </div>


  </section>
</template>

<script>
import filmItem from "@/components/filmItem";
import axios from "axios";
import Swiper, {Navigation, Pagination} from "swiper";

export default {
  name: "resources",
  components: {
    filmItem
  },
  data: function () {
    return {
      logoUrl: 'img/optimedia.svg',
      dataFromApi: []
    }
  },

  mounted() {
    new Swiper(".film-swipe", {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 15,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".freeSwiper-button-next",
        prevEl: ".freeSwiper-button-prev"
      },
      breakpoints: {
        0: {
          slidesPerView: "auto",
          spaceBetween: 10
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        650: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        900: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1050: {
          slidesPerView: 5
        },
        1180: {
          slidesPerView: 6
        },
        1350: {
          slidesPerView: 7
        },
        1500: {
          slidesPerView: 8
        }
      }

    });
  },

  methods: {



  },

  async created() {

    const DATA_FROM_API = await axios.get("https://admin.optikom.uz/api/v1/web/getOptimedia");
    this.dataFromApi = DATA_FROM_API.data.data;



  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.film-swipe {
  position: relative;
}

.swiper-wrapper {
  padding: 20px 0;
}

.optimedia {
  padding: 100px 0;
  background: #111111;

  &__logo {
    display: flex;
    justify-content: center;
    margin: 45px 0;

    img {
      width: 100%;
    }
  }

  &__text {
    h2 {
      font-weight: 700;
      font-size: 4.6rem;
      line-height: 132.5%;
      text-align: center;
      color: $colorWhite;
      display: flex;
      flex-direction: column;

      small {
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 132.5%;
        text-align: center;
        color: $colorWhite;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 50px 0;

  }

  &__film {
    position: relative;


  }

  .section__title {
    h2 {
      color: $colorWhite !important;
      justify-content: center;

      a {
        color: $colorWhite !important;
      }
    }
  }
}

@keyframes lighten {
  0% {
    background: lighten($colorBlack, 10%);
  }
  50% {
    background: lighten($colorBlack, 30%);
  }
  100% {
    background: lighten($colorBlack, 10%);
  }

}

</style>